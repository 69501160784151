import WKT from "ol/format/WKT";
import {Vector} from "ol/source";
import {Vector as VectorLayer} from "ol/layer/";
import {Feature} from "ol";
import {transform} from "ol/proj";
import {Stroke, Style, Circle} from "ol/style";
import {Fill} from "ol/style";
import {DEVICE_PIXEL_RATIO} from "ol/has";
import {toLonLat} from "ol/proj";
import Vue from "vue";
import {mapActions, mapGetters} from "vuex";

const coordsObj = {lat: '', long: ''};
let selectedFeatures = {};

const selectionLayer = {};

function setClickedCoordinates(coords){
    const longLat = toLonLat(coords);
    coordsObj.lat = longLat[1];
    coordsObj.long = longLat[0];
}

function getClickedCoordsObj(){
    return coordsObj;
}

function setSelectedFeatures(featureMap){
    clearHighlightOfFeatures();
    Object.values(selectionLayer).forEach(layer => {
        layer.getSource().clear();
    });
    selectedFeatures = featureMap;
    addSelectedFeaturesToLayer(Object.values(selectionLayer));
}

function rehighlightSelectedDwdFeatures(){
    if(selectedFeatures["dwd"]) {
        selectedFeatures["dwd"].features.forEach(f => highlightFeature(f, true));
    }
}

function highlightFeature(feature, diagonal){
    // diagonal -> direkt in der Karte selektiert
    // senkrecht -> über InfoPanel dazu gewählt
    if(feature.getGeometry().getType() === "Point") {
        feature.setStyle(new Style({
            image: new Circle({
                radius: 6,
                fill: null,
                stroke: new Stroke({
                    color: '#CD7300',
                    width: 3
                })
            })
        }));
    } else { // Polygon
        let color = '#CD7300';
        if(feature.getStyle()){
            color = feature.getStyle().getFill().getColor()
        }
        feature.setStyle(new Style({
            // diagonal === null, wenn feature = Heatmapzelle
            fill: diagonal === null ? null : new Fill({
                color: _createSelectionPattern(color, diagonal)
            }),
            stroke: new Stroke({
                color: feature.getProperties().zoom !== undefined ? '#FF0000' : (diagonal === true ? '#CD7300' : '#3f2700'),
                lineDash: feature.getProperties().zoom !== undefined ? [8, 8] : null,
                width: diagonal === true ? 4 : 2})
        }));
    }
}

function clearHighlightOfFeatures(){
    if(!selectedFeatures){
        return;
    }

    const layerNames = Object.keys(selectedFeatures);
    layerNames.forEach(name => {
        selectedFeatures[name].features.forEach(f => {
            _clearHighlightOfFeature(f, selectedFeatures[name].layer);
            if(name === "dwd"){
                // TODO: Zeile darüber notwendig?
                // TODO: Features ohne Farbe, Rand wird blau statt schwarz...
                f.setStyle(f.get("___currentStyle"));
            }
        });
    });
}

function _clearHighlightOfFeature(feature, layer){
    if(feature.getGeometry().getType() === "Point") {
        feature.setStyle(layer.get('defaultPointStyle'));
    } else if(layer.get('name') === 'solar'){
        feature.setStyle(feature.get('___solarStyle'));
    } else {
        let style = layer.get('defaultPolygonStyle');
        if(!style){
            style = layer.get('defaultStyle')
        }
        feature.setStyle(style);
    }
}

function _createSelectionPattern(color, diagonal){
    // diagonal -> direkt in der Karte selektiert
    // senkrecht -> über InfoPanel dazu gewählt
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    // offset soll Streifen etwas verschieben, damit nicht alle übereinander liegen bei überlappenden features
    // hilft aber nur bedingt, aber leider bietet openlayers direkt keine bessere Möglichkeit
    // (Problem: Pattern wird global betrachtet)
    const offset = + Math.round(Math.random() * 10);
    canvas.width = 40 * DEVICE_PIXEL_RATIO + offset;
    canvas.height = 40 * DEVICE_PIXEL_RATIO + offset;

    context.lineWidth = diagonal ? 4 : 3;
    context.strokeStyle = color;
    context.beginPath();
    context.lineCap = "square";

    if(diagonal) {
        // Streifen so ziehen, dass sie zu anliegenden Kacheln nahtlos übergehen
        context.moveTo(10, 0);
        context.lineTo(canvas.width, canvas.height - 10);
        context.moveTo(0, 10);
        context.lineTo(canvas.width - 10, canvas.height);
        context.moveTo(canvas.width - 10, 0);
        context.lineTo(canvas.width, 10);
        context.moveTo(0, canvas.height - 10);
        context.lineTo(10, canvas.height);
    } else{
        context.moveTo(canvas.width/2-20, 0);
        context.lineTo(canvas.width/2-20, canvas.height);
        context.moveTo(canvas.width/2+20, 0);
        context.lineTo(canvas.width/2+20, canvas.height);
    }
    context.stroke();

    return context.createPattern(canvas, 'repeat');
}

function addSelectionLayer(mapId){
    // Selektierte features landen in diesem Layer, sind für alle Karten gleich (für brushing)
    const selectedFeaturesLayer = new VectorLayer({
        source: new Vector({
            features: [],
            zIndex: 20,
            wrapX: false
        }),
        l_name: "selectedFeaturesLayer",
    });
    selectedFeaturesLayer.set('name', '___selection');
    selectionLayer[mapId] = selectedFeaturesLayer;
    // füge alles features hinzu:
    addSelectedFeaturesToLayer([selectedFeaturesLayer]);
    return selectedFeaturesLayer;
}

function addSelectedFeaturesToLayer(layerList){
    if(selectedFeatures){
        Object.values(selectedFeatures).forEach(obj => {
            obj.features.forEach(f => {
                highlightFeature(f, true);
                layerList.forEach(layer => {
                    if(!layer.getSource().hasFeature(f)){
                        layer.getSource().addFeature(f);
                    }
                });
            });
        });
    }
}

function addFeatureToSelection(feature, category, layer){
    if(!selectedFeatures[category]){
        selectedFeatures[category] =  {layer: layer, features: [], counter: 0};
    }
    selectedFeatures[category].features.push(feature);
    selectedFeatures[category].counter += 1;

    Object.values(selectionLayer).forEach(layer => {
        if(!layer.getSource().hasFeature(feature)){
            layer.getSource().addFeature(feature);
        }
    });
    highlightFeature(feature, false);
}

function removeFeatureFromSelection(feature, category){
    if(selectedFeatures[category]){
        selectedFeatures[category].features.slice(selectedFeatures[category].features.indexOf(feature), 1);
        selectedFeatures[category].counter -= selectedFeatures[category].features.length;
        Object.values(selectionLayer).forEach(layer => {
            if(layer.getSource().hasFeature(feature)){
                layer.getSource().removeFeature(feature);
            }
        });
    }
    _clearHighlightOfFeature(feature, selectedFeatures[category].layer);
}

function removeSelectionLayer(mapId){
    delete selectionLayer[mapId];
}

function getSelectedFeatures(){
    return selectedFeatures;
}


export {
    setClickedCoordinates,
    getClickedCoordsObj,
    setSelectedFeatures,
    addSelectionLayer,
    addFeatureToSelection,
    removeFeatureFromSelection,
    removeSelectionLayer,
    rehighlightSelectedDwdFeatures,
    getSelectedFeatures,
}
