
const _neo4j = {};
_neo4j.username = ''; //'neo4j';
_neo4j.password = ''; //'lmbv.informatik.uni-leipzig2020!';
_neo4j.port = '11114';
//_neo4j.url = 'bolt://seneca.informatik.uni-leipzig.de';
//_neo4j.url = 'bolt://virtlehre.informatik.uni-leipzig.de';
//_neo4j.url = 'bolt://sardine.vda-group.de';
//_neo4j.url = 'bolt://139.18.13.224';
//_neo4j.url = 'bolt://remote.sca-ds.de';
_neo4j.url = 'bolt://sardine.vda-group.de';

const _senergy = {};
_senergy.username = ''; //'sardine';
_senergy.password = ''; //'123fisch.';
_senergy.url = 'https://auth.senergy.infai.org/auth/realms/master/protocol/openid-connect/token';

const _openlayers = {};
//_openlayers.url = "http://seneca.informatik.uni-leipzig.de:8080/styles/positron/{z}/{x}/{y}.png";
_openlayers.url = "https://opentiles.vda-group.de/styles/positron/{z}/{x}/{y}.png";
//_openlayers.url = "https://tile.openstreetmap.org/{z}/{x}/{y}.png";

export {_neo4j, _senergy, _openlayers};
