import * as Neo4jController from "./Neo4jController";
import WKT from "ol/format/WKT";
import {Vector} from "ol/source";
import {Vector as VectorLayer} from "ol/layer/";
import {Feature} from "ol";
import {transform} from "ol/proj";
import {Stroke, Style, Circle} from "ol/style";
import {Fill} from "ol/style";
import Vue from "vue";
import featureBin from "ol-ext/source/FeatureBin";
import * as MapData from "../modules/MapData";

let textUpdateReceiver = null;
// TODO: layerData und retLayerData benötigt? Namen passend? Vue.set anders nutzen?
const layerData = {};
const retLayerData = {};
const restrictionLayerData = {};
const retRestrictionLayerData = {map: {}};
const countyMap = Vue.observable({ids: null, activeFeatures: null, changed: Vue.observable({value: 0})});
const activeFeatureLayer = {layer: {}, length: Vue.observable({value: 0})}; // TODO: besser alls mit observable und nicht Vue.set?; TODO: length.value als Abfrage ist doof!

async function loadLayerNames(){
    textUpdateReceiver.setText("Loading Layer Names", "");
    const query = "MATCH (nodes)-[:at]->() RETURN distinct toString(LABELS(nodes)[0]) as label order by label";
    const result = await Neo4jController.runCypherQuery(query);

    result.records.forEach(rec => {
        const label = rec.get("label");
        if(label === "brunnen" || label === "pegel" || label === "flurabstand_endzustand"){
            return;
        }
        layerData[label] = {};
        //console.log(label);
    });
    //layerData['rain'] = {};
}

async function loadRestrictionsNames(type){
    textUpdateReceiver.setText("Loading Layer Names", `(${type})`);
    const query = "MATCH (:" + type + ")-[]->(m) RETURN distinct toString(LABELS(m)[0]) as label order by label";
    const result = await Neo4jController.runCypherQuery(query);

    restrictionLayerData[type] = {};
    result.records.forEach(rec => {
        restrictionLayerData[type][rec.get("label")] = {};
    });
}

async function loadLayerData(){
    //const toLoad = ['landinanspruchnahme', 'schutzgebiete', 'dwd', 'abschlussbetriebsplan', 'wegeplanung'];
    const toLoad = Object.keys(layerData);
    for(let i=0; i<toLoad.length; i++){
        const name = toLoad[i];

        textUpdateReceiver.setText("Loading Layer Data", "(" +name + ": " + (i+1) + " of " + toLoad.length + ")");

        const query = "MATCH (obj)-[:at]->(coords) " +
            "WHERE LABELS(obj)[0] =  '" + name + "' AND coords.invalid_shape = 'false' " +
            "OPTIONAL MATCH (obj)-[:has]-(doc)-[r]-(t)-[]-(:DocTypes) " +
            "WITH obj, coords.wkt as wkt, ID(obj) as id, doc, COLLECT({type: r, labels: labels(t)[0]}) as row " +
            "RETURN obj, wkt, id, COLLECT({document: doc, types: row}) as documents";
        const result = await Neo4jController.runCypherQuery(query);

        const features = [];

        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        const polygonStyle = new Style({
            fill: new Fill({
                color: `rgba(${r}, ${g}, ${b}, 0.48)`, //"#17621A7A"
            }),
            stroke: new Stroke({
                color: '#000000',
                width: 1
            })
        });

        const pointStyle = new Style({
            image: new Circle({
                radius: 6,
                fill: new Fill({
                    color: `rgba(${r}, ${g}, ${b}, 0.48)`,
                }),
                stroke: new Stroke({
                    color: 'black',
                    //lineDash: [6, 6],
                    width: 1
                })
            })
        });


        result.records.forEach(f => {
            const feature = new WKT().readFeature(f.get('wkt'));
            feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
            feature.setProperties(f.get("obj").properties);

            if (name === "solar"){
                const style = new Style({
                    fill: new Fill({
                        color: _getSolarColor(feature.get("value")) + "aa",
                    }),
                    stroke: new Stroke({
                        color: '#4e4e4e',
                        width: 0.03
                    })
                });
                feature.setStyle(style);
                feature.set('___solarStyle', style);
            } else if(feature.getGeometry().getType() === "Point"){
                feature.setStyle(pointStyle);
            } else {
                feature.setStyle(polygonStyle);
            }

            if(name === "dwd"){
                // TODO: set white/gray color for layer
                feature.setId(feature.getProperties().WarnCellID);
            } else {
                feature.setId(f.get('id').toString());
            }

            feature.set('___documents', _createDocumentsObject(f.get('documents')));
            feature.set('___id', f.get('id').toString());
            feature.set('___mainCategory', name);

            features.push(feature);
        });

        const layer = new VectorLayer({
            source: new Vector({
                features: features,
                wrapX: false
            }),
        });
        layer.set('name', name);
        layer.set('defaultColor', `rgba(${r}, ${g}, ${b})`);
        // TODO: Gibt es gemischte Layer? Wie damit umgehen?
        layer.set('defaultPolygonStyle', polygonStyle);
        layer.set('defaultPointStyle', pointStyle);

        layerData[name]['layer'] = layer;
    }
    setDWDColors([]);
    Vue.set(retLayerData, 'map', layerData);
}

async function loadRestrictionsLayerData(type){
    //const toLoad = ['Wetter'];
    const toLoad = Object.keys(restrictionLayerData[type]);
    for(let i=0; i<toLoad.length; i++){
        const name = toLoad[i];

        textUpdateReceiver.setText("Loading Layer Data", "(" + type + " => " +name + ": " + (i+1) + " of " + toLoad.length + ")");

        const query = "MATCH (cat)<-[:" + type.toLowerCase() + "]-(doc:documents)<-[:has]-(obj)-[:at]->(coords) " +
                "WHERE LABELS(cat)[0] = '" + name + "' AND coords.invalid_shape = 'false' " +
                "OPTIONAL MATCH (doc)-[r]-(t)-[]-(:DocTypes) " +
                "WITH obj, coords.wkt as wkt, ID(obj) as id, doc, COLLECT({type: r, labels: labels(t)[0]}) as row " +
                "RETURN obj, LABELS(obj)[0] as label, wkt, id, COLLECT({document: doc, types: row}) as documents";

        const result = await Neo4jController.runCypherQuery(query);

        const features = [];

        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        const style = new Style({
            fill: new Fill({
                color: `rgba(${r}, ${g}, ${b}, 0.48)`, //"#17621A7A"
            }),
            stroke: new Stroke({
                color: '#000000',
                width: 1
            })
        });
        result.records.forEach(f => {
            const feature = new WKT().readFeature(f.get('wkt'));
            feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
            feature.setProperties(f.get("obj").properties);
            feature.setStyle(style);
            feature.setId(f.get('id').toString());

            feature.set('___documents', _createDocumentsObject(f.get('documents')));
            feature.set('___id', f.get('id').toString());
            feature.set('___mainCategory', f.get('label'));

            features.push(feature);
        });

        const layer = new VectorLayer({
            source: new Vector({
                features: features,
                wrapX: false
            }),
        });
        layer.set('name', name);
        layer.set('defaultColor', `rgba(${r}, ${g}, ${b})`);
        layer.set('defaultStyle', style);

        restrictionLayerData[type][name]['layer'] = layer;
    }
    Vue.set(retRestrictionLayerData.map, type, restrictionLayerData[type]);
}

async function loadIntersectionsCounterForFeature(featureId){
    const query = "MATCH (n)-[:at]-()-[:intersects]-(b) " +
                    "WHERE Id(n) = toInteger(" + featureId + ") " +
                    "RETURN LABELS(b)[0] as label, count(LABELS(b)[0]) as counter order by LABELS(b)[0]";
    const result = await Neo4jController.runCypherQuery(query);

    const data = {};
    result.records.forEach(rec => {
        let layerName = rec.get('label').replace('_coordinates', '');
        if (layerName === "wegenetz"){
            layerName = "wegeplanung"; // TODO: Label wurde in DB geändert, aber nicht für wktLayer!
        }

        const counter = rec.get('counter').toString();
        if(!layerData[layerName]){
            return; // TODO: Weil oben Brunnen usw. gefiltert wurden für Demo!
        }
        data[layerName] = {features: [], layer: layerData[layerName].layer, counter: counter};
    });

    return data;
}

async function loadIntersectionsForFeatureInCategory(featureId, category){
    const query = 'MATCH (n)-[:at]-()-[:intersects]-()-[:at]-(b:' + category + ') ' +
        'WHERE Id(n) = toInteger(' + featureId + ') ' +
        'RETURN Id(b) as id';
    const result = await Neo4jController.runCypherQuery(query);

    const features = [];
    result.records.forEach(rec => {
        const id = rec.get('id').toString();
        features.push(layerData[category].layer.getSource().getFeatureById(id));
    });

    return features;
}

async function loadDates() {
    let relations = ["withPerson", "withCompany", "gebot", "verbot",  "firstType", "secondType"]
    const query = 'MATCH (d:documents) OPTIONAL MATCH (d)-[r]-(o) ' +
                'WHERE type(r) IN $relations ' +
                'WITH d, {label:labels(o)[0], type:type(r), properties: o} as Neighbor ' +
                'WITH d, collect(Neighbor) as n ' +
                'RETURN ID(d) as dID, d.Description as des, d.Tags as tags, d.allDates as t, n'
    const result = await Neo4jController.runCypherQuery(query, {"relations": relations}); 
    let data = []
    result.records.forEach(rec => {
        let n = rec.get('n')  
        n = n.filter(x => x.properties).map(x => ( {"name": x.properties.properties.name, "type": x.type, "label": x.label }))
        let t = rec.get('t')
        let des = (rec.get('des') ? rec.get('des') : "") 
        let tags = (rec.get('tags') ? rec.get('tags') : "") 
        let dates = (t ? t.split(", ") : [])
        dates = dates.map(x => parseDate(x)).sort(function(a, b) {
            if (a.year > b.year) {
                return 1
            } else if (a.year < b.year) {
                return -1
            } else {
                if (a.month > b.month) {
                    return 1
                } else if (a.month < b.month) {
                    return -1
                } else {
                    if (a.day > b.day) {
                        return 1
                    } else if (a.day < b.day) {
                        return -1
                    } 
                }
            }
            return 0
        });
        data.push({"doc": toNumber(rec.get('dID')), "description": des, "tags": tags, "dates": dates, "neighbors": n})
    });
    //console.log(data)
    return  data

}

async function loadFeaturesInRegion(region, layerNames) {
    let relations = ["withPerson", "withCompany", "gebot", "verbot",  "firstType", "secondType"]
    let data = []
    layerNames = layerNames.filter(value => Object.keys(layerData).includes(value))
    //console.log(layerNames)
    // TODO only temp for test and dev -> if nothing selected load all data
    if (layerNames.length === 0) {
        return loadDates()
    }
    // use only selected layers
    for (let iLayer of layerNames){
        if(iLayer === "wegeplanung"){
            iLayer = "wegenetz";
        }
        const result = await Neo4jController.runCypherQuery(
            `CALL spatial.intersects("${iLayer}", "${region}") YIELD node
            WITH node as coord 
            MATCH (coord)-[]-(f:${iLayer})-[]-(d:documents)
            OPTIONAL MATCH (d)-[r]-(o)  
                WHERE type(r) IN $relations 
                WITH d, {label:labels(o)[0], type:type(r), properties: o} as Neighbor 
                WITH d, collect(Neighbor) as n 
            RETURN ID(d) as dID, d.Description as des, d.Tags as tags, d.allDates as t, n`,  {"relations": relations}
        );
        result.records.forEach(rec => {
            let n = rec.get('n')  
            n = n.filter(x => x.properties).map(x => ( {"name": x.properties.properties.name, "type": x.type, "label": x.label }))
            let t = rec.get('t')
            let des = (rec.get('des') ? rec.get('des') : "") 
            let tags = (rec.get('tags') ? rec.get('tags') : "") 
            let dates = (t ? t.split(", ") : [])
            dates = dates.map(x => parseDate(x)).sort(function(a, b) {
                if (a.year > b.year) {
                    return 1
                } else if (a.year < b.year) {
                    return -1
                } else {
                    if (a.month > b.month) {
                        return 1
                    } else if (a.month < b.month) {
                        return -1
                    } else {
                        if (a.day > b.day) {
                            return 1
                        } else if (a.day < b.day) {
                            return -1
                        } 
                    }
                }
                return 0
            });
            data.push({"doc": toNumber(rec.get('dID')), "description": des, "tags": tags, "dates": dates, "neighbors": n})
        });
    }
    return data
}

function parseDate(date) {
    let objDate = {}
    if (date.indexOf(".") !== -1) {
        let temp = date.split(".").map(x => x.replace(",", ""))
        if (temp.length == 3) {
            objDate = {"year": Number(temp[2]), "month": Number(temp[1]), "day": Number(temp[0]), "uncertainDay": false, "uncertainMonth": false}
        } else if (temp.length == 2){
            objDate = {"year": Number(temp[1]), "month": Number(temp[0]), "day": 1, "uncertainDay": true, "uncertainMonth": false}
            //console.log(date)
        } else {
            //console.log(date)
        }
    } else if (date.indexOf("-") !== -1) {
        let temp = date.split("-").map(x => x.replace(",", ""))
        if (temp.length == 3) {
            objDate = {"year": Number(temp[2]), "month": Number(temp[1]), "day": Number(temp[0]), "uncertainDay": false, "uncertainMonth": false}
        } else if (temp.length == 2){
            objDate = {"year": Number(temp[1]), "month": Number(temp[0]), "day": 1, "uncertainDay": true, "uncertainMonth": false}
            //console.log(date)
        } else {
            //console.log(date)
        }
    } else {
        objDate = {"year": Number(date), "month": 1, "day": 1, "uncertainDay": true,  "uncertainMonth": true}
        //console.log(date)
    }
    return objDate
}

function toNumber({ low, high }) {
  let res = high

  for (let i = 0; i < 32; i++) {
    res *= 2
  }

  return low + res
}

//only use pre-selected document ids
async function getFeaturesByDocumentIdsWithDate(fromDate, toDate, ids, brutzeiten){
    let result = null;
    if(brutzeiten){
        result = await Neo4jController.runCypherQuery(
            'MATCH (f)-[:has]-(d:documents)-[r:gebot]-(b:Brutzeiten) ' +
            'WHERE (r.end_time >= Date("' + fromDate + '") AND r.start_time <= Date("' + toDate + '")) AND ID(d) in [' + ids.toString() + '] ' +
            'RETURN COLLECT(DISTINCT ID(d)) as dId, ID(f) as fId, labels(f)[0] as category;'
        );
    } else {
        result = await Neo4jController.runCypherQuery(
            'MATCH (f)-[:has]-(d:documents)-[]-(t:timeNode) ' +
            'WHERE ID(d) in [' + ids.toString() + '] AND t.date >= Date("' + fromDate + '") AND t.date <= Date("' + toDate + '") ' +
            'RETURN COLLECT(DISTINCT ID(d)) as dId, ID(f) as fId, labels(f)[0] as category;'
        );
    }
    const dIds = new Set();
    const featureMap = {};
    const fCounter = result.records.length;

    result.records.forEach(rec => {
        rec.get('dId').forEach(d => {
            dIds.add(d.toString());
        });
        const category = rec.get('category');
        const fId = rec.get('fId').toString();
        if(!featureMap[category]){
            featureMap[category] = [];
        }
        featureMap[category].push(fId);
    });
    return {dIds, featureMap, fCounter, layerData};
}

async function loadDocumentIdsWithDate(fromDate, toDate, geboteLabels, verboteLabels){
    let gebote = "";
    geboteLabels.forEach(label => {
        gebote += " AND exists( (d)-[:gebot]-(:" + label + "))";
    });

    let verbote = "";
    verboteLabels.forEach(label => {
        verbote += " AND exists( (d)-[:verbot]-(:" + label + "))";
    });

    const query = 'MATCH (f)-[:has]-(d:documents)-[]-(t:timeNode) ' +
        'WHERE t.date >= Date("' + fromDate + '") AND t.date <= Date("' + toDate + '") ' +
        gebote + verbote + " " +
        'RETURN COLLECT(DISTINCT ID(d)) as dId, ID(f) as fId, labels(f)[0] as category;';
    console.log(query);

    const result = await Neo4jController.runCypherQuery(query);
    const dIds = new Set();
    const featureMap = {};
    const fCounter = result.records.length;

    result.records.forEach(rec => {
        rec.get('dId').forEach(d => {
            dIds.add(d.toString());
        });
        const category = rec.get('category');
        const fId = rec.get('fId').toString();

        if(!featureMap[category]){
            featureMap[category] = [];
        }
        featureMap[category].push(fId);
    });
    return {dIds, featureMap, fCounter, layerData};
}

async function loadPersonsAndCompanies(){
    const query = 'MATCH (n:Person) WITH COLLECT(n.name) as persons MATCH (m:Company) RETURN persons, COLLECT(m.name) as companies;';
    const result = await Neo4jController.runCypherQuery(query);
    const persons = result.records[0].get('persons');
    const companies = result.records[0].get('companies');
    return {persons: persons, companies: companies};
}

async function loadDocumentIdsWithPersonAndCompany(persons, companies){
    const pList = '["' + persons.join('","') + '"]';
    const cList = '["' + companies.join('","') + '"]';
    let query = "";
    if(persons.length > 0 && companies.length > 0) {
        query = 'MATCH (p:Person)-[r1]-(d:documents)-[r2]-(c:Company), (d)-[:has]-(f) ' +
            'WHERE p.name in ' + pList + ' AND c.name in ' + cList + ' ' +
            'RETURN DISTINCT COLLECT(DISTINCT ID(d)) as dID, ID(f) as fID, labels(f)[0] as category;';
    } else if(persons.length > 0){
        query = 'MATCH (p:Person)-[r1]-(d:documents)-[r2]-(c:Company), (d)-[:has]-(f) ' +
            'WHERE p.name in ' + pList + ' ' +
            'RETURN DISTINCT COLLECT(DISTINCT ID(d)) as dID, ID(f) as fID, labels(f)[0] as category;';
    } else if(companies.length > 0){
        query = 'MATCH (p:Person)-[r1]-(d:documents)-[r2]-(c:Company), (d)-[:has]-(f) ' +
            'WHERE c.name in ' + cList + ' ' +
            'RETURN DISTINCT COLLECT(DISTINCT ID(d)) as dID, ID(f) as fID, labels(f)[0] as category;';
    }

    if(query !== ""){
        const result = await Neo4jController.runCypherQuery(query);
        const dIds = new Set();
        const featureMap = {};
        const fCounter = result.records.length;
        result.records.forEach(rec => {
            rec.get("dID").forEach(d => {
                dIds.add(d.toString());
            });
            const category = rec.get('category');
            const fId = rec.get('fID').toString();

            if(!featureMap[category]){
                featureMap[category] = [];
            }
            featureMap[category].push(fId);
        });
        return {dIds, featureMap, fCounter, layerData};
    }
    return null;
}

async function loadFeaturesByDocumentIDs(docIDs){
    if(docIDs.length === 0){
        return {dIds: new Set(), featureMap: {}, fCounter: 0, layerData};
    }

    const liste = '["' + docIDs.join('","') + '"]';
    const query = `MATCH (d:documents)-[:has]-(f) WHERE d.FileName in ${liste} RETURN DISTINCT ID(f) as fID, LABELS(f)[0] as category, ID(d) as dID`;
    const result = await Neo4jController.runCypherQuery(query);

    const dIds = new Set();
    const featureMap = {};
    const fCounter = result.records.length;
    result.records.forEach(rec => {
        const category = rec.get('category');
        const fId = rec.get('fID').toString();
        dIds.add(rec.get('dID').toString());
        if(!featureMap[category]){
            featureMap[category] = [];
        }
        featureMap[category].push(fId);
    });
    return {dIds, featureMap, fCounter, layerData}; // TODO: layerData in Store speichern!?
}

async function loadRestrictions(featureId, type){
    const query = 'MATCH (n)-[:has]-(d:documents)-[rv: ' + type + ']-(v), ' +
                  '(d:documents)-[rt:firstType]-(type) ' +
                    'WHERE Id(n) = toInteger(' + featureId + ') ' +
                        'RETURN DISTINCT d.FileName as FileName, d.Description as Description, ' +
                            'rv.sentence as sentence, rv.confidence as confidence, ' +
                            'rv.confidence_sub as confidence_sub, rv.wTypes as wTypes, ' +
                            'rv.pageNum as pageNum, ' +
                            'LABELS(v)[0] as category, labels(type)[0] as type ' +
                        'order by category, confidence_sub DESC';
    // TODO: Unterschied zwischen confidence und confidence_sub?
    // TODO: Fehler in den Daten? 86133 hat gleiche Verbote in Entsorgung und Geotechnisch!
    const result = await Neo4jController.runCypherQuery(query);

    const data = {};

    const duplikate = {};

    result.records.forEach(rec => {
        // sortiere in die richtige Liste ret[Typ].push()
        // category z.B. "aufenthalt", Typ der Restriction
        if(duplikate[rec.get("category")] && duplikate[rec.get("category")].has(rec.get("sentence"))){
            return;
        }
        if(!data[rec.get("category")]) {
            data[rec.get("category")] = [];
            duplikate[rec.get("category")] = new Set();
        }
        duplikate[rec.get("category")].add(rec.get("sentence"));
        data[rec.get("category")].push({
            filename: rec.get("FileName"),
            description: rec.get("Description"),
            sentence: rec.get("sentence"),
            pageNum: rec.get("pageNum"),
            type: rec.get("type"),
            wTypes: rec.get("wTypes"),
            // if wetter -> add label and check dwd values
            //dwdDate: "",
        });
    });
/*
    if(featureId === '86133'){
        if(!data["Wetter"]){
            data["Wetter"] = [];
        }
        data["Wetter"].push({
            filename: "test.pdf",
            description: "2014-09-05 - 67.Ergänzung TF Harthkanal - Darstellung",
            sentence: "Bei Starkregen sind die Uferbereiche nicht mehr zu betreten.",
            type: "Plan",
            dwdDate: '2022-05-17',
        });
        data["Wetter"].push({
            filename: "test.pdf",
            description: "2014-09-05 - 67.Ergänzung TF Harthkanal - Koordination",
            sentence: "Bei Sturmböen ist der Aufenthalt im Uferbereich untersagt. Leicht bewegliche Maschinen sind zu entfernen.",
            type: "Plan",
            dwdDate: '2022-05-21',
        });
        data["Wetter"].push({
            filename: "test.pdf",
            description: "2014-09-05 - 67.Ergänzung TF Harthkanal - Darstellung",
            sentence: "Betrieb und Aufenthalt am Stahlturm sind bei Gewitter und ähnlichem Wetter untersagt. Es besteht die Gefahr eines Blitzschlags.",
            type: "Plan",
            dwdDate: '2022-05-17, 2022-05-16',
        });
    }
*/
    return data;
}

async function savePolygonInDB(layerName, wkt){
    // To clear:
    // MATCH (n:wegeplanung) WHERE EXISTS(n.created) detach delete n
    // MATCH (n:wegenetz_coordinates) WHERE NOT EXISTS (n.Nr) detach delete n

    layerName = "wegeplanung"; // TODO: ;)
    textUpdateReceiver.setText("Saving Polygon", "");

    // TODO: if selectedType new? -> CALL spatial.addWKTLayer('NAME', 'wkt')

    // CREATE (n:NAME {ATTR}) RETURN n; // Node itself
    let nodeID = null;
    const time = new Date().toString();
    await Neo4jController.runCypherQuery(
        `CREATE (n:${layerName} {created: "${time}"}) RETURN ID(n) as ID`
    ).then(data => {
        nodeID = data.records[0].get("ID").toString();
    });
    // CREATE (:NAME_coordinates {key, invalid_shape: 'false', wkt: ""})// Coordinates Node
    textUpdateReceiver.setText("Saving Polygon Coordinates", "");
    let nodeCoordsID = null;
    await Neo4jController.runCypherQuery(
        `CREATE (n:wegenetz_coordinates {invalid_shape: 'false', wkt: "${wkt}"}) RETURN ID(n) as ID`
    ).then(data => {
        nodeCoordsID = data.records[0].get("ID").toString();
    });
    // MATCH (a:NAME), (b:NAME_coordinates) WHERE a.KEY = b.KEY CREATE (a)-[c:at]->(b) RETURN a, b, c// Link nodes
    textUpdateReceiver.setText("Saving Polygon - Linking", "");
    await Neo4jController.runCypherQuery(
        `MATCH (a:${layerName}), (b:wegenetz_coordinates) WHERE ID(a) = ${nodeID} AND ID(b) = ${nodeCoordsID} CREATE (a)-[c:at]->(b)`);
    // MATCH (a:NAME_coordinates) WHERE a.KEY = KEY CALL spatial.addNodes('NAME', COLLECT(a)) YIELD count RETURN count // push Coordinates Node to wktLayer
    textUpdateReceiver.setText("Saving Polygon - Calculate Intersections", "");
    await Neo4jController.runCypherQuery(
        `MATCH (b:wegenetz_coordinates) WHERE ID(b) = ${nodeCoordsID} WITH COLLECT(b) as node CALL spatial.addNodes('wegenetz', node) YIELD count RETURN count`);
    // MATCH (n:NAME_coordinates) WHERE n.KEY=KEY CALL spatial.intersects('LAYER', n.wkt) yield node as poly CREATE (n)-[r:intersects]->(poly) RETURN n, poly, r // do intersection
    for (let iLayer of Object.keys(layerData)){
        if(iLayer === "wegeplanung"){
            iLayer = "wegenetz";
        }
        await Neo4jController.runCypherQuery(
            `MATCH (b:wegenetz_coordinates) WHERE ID(b) = ${nodeCoordsID} CALL spatial.intersects("${iLayer}", b.wkt) YIELD node as poly WHERE poly <> b CREATE (b)-[r:intersects]->(poly) RETURN 1`);
    }

    // insert to Layer
    const feature = new WKT().readFeature(wkt);
    feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    feature.setProperties({created: time});
    feature.setStyle(layerData[layerName].layer.getProperties().defaultStyle);
    feature.setId(nodeID);
    feature.set('___documents', []);
    feature.set('___id', nodeID);
    feature.set('___mainCategory', layerName);

    layerData[layerName].layer.getSource().addFeature(feature);

    return {layer: layerData[layerName].layer, feature: feature};
}

async function loadCountyRestrictionsIds(){
    textUpdateReceiver.setText("Loading County Data", "");
    layerData['dwd'].layer.getSource().getFeatures().forEach(f => {

    });

    const query = "MATCH (n:dwd)-[:at]->()<-[:intersects]-()<-[:at]-(m)-[]->()-[r5]->(:Wetter) " +
        "WHERE EXISTS(r5.wTypes) " +
        "RETURN n.WarnCellID as idCounty, Id(m) as idFeature, labels(m)[0] as catFeature, r5.wTypes as wTypes " +
        "ORDER BY idCounty, catFeature";

    const result = await Neo4jController.runCypherQuery(query);

    const dataObj = {};
    result.records.forEach(rec => {
        const idCounty = rec.get("idCounty");
        const idFeature = rec.get("idFeature");
        const catFeature = rec.get("catFeature");
        const wTypes = rec.get("wTypes");

        if(!dataObj[idCounty]){
            dataObj[idCounty] = {};
        }
        if(!dataObj[idCounty][idFeature]){
            dataObj[idCounty][idFeature] = {category: null, wTypes: ""};
        }
        dataObj[idCounty][idFeature]["category"] = catFeature;
        dataObj[idCounty][idFeature]["wTypes"] += wTypes;
    });
    countyMap.ids = dataObj;

    const layer = new VectorLayer({
        source: new Vector({
            features: [],
            wrapX: false
        }),
    });
    const style = new Style({
        fill: new Fill({
            color: "#17621A7A"
        }),
        stroke: new Stroke({
            color: '#000000',
            width: 1
        })
    });
    layer.set('name', "currentlyActiveFeatures");
    layer.set('defaultColor', "#500037");
    layer.set('defaultStyle', style);
    Vue.set(activeFeatureLayer, 'layer', layer);
}

function _createDocumentsObject(documents){
    const obj = {};

    documents.forEach(doc => {
        if (!doc.document) {
            return;
        }

        const docProperties = {};
        docProperties.description = doc.document.properties.Description;
        docProperties.filename = doc.document.properties.FileName;
        docProperties.filetime = doc.document.properties.FileTime;
        docProperties.id = doc.document.identity.toString();

        // TODO: Fehler in DB, manchmal doppelte Kanten zu den Typen!
        let firstType = null;
        let secondType = null;
        if(doc.types[0].type === "firstType"){
            firstType = doc.types[0];
            secondType = doc.types[1];
        } else {
            firstType = doc.types[1];
            secondType = doc.types[0];
        }

        docProperties.firstTypeProbability = firstType.type.properties.probability;
        docProperties.firstType = firstType.labels;
        docProperties.secondTypeProbability = secondType.type.properties.probability;
        docProperties.secondType = secondType.labels;
        // TODO: Fehler in DB, manchmal fehlt diese Angabe!
        //  (Kommt vermutlich durch den Fehler oben, hier dann firstType als secondType angenommen)
        docProperties.uncertainty = secondType.type.properties.uncertainty;

        if(obj[firstType.labels]){
            obj[firstType.labels].push(docProperties);
        } else {
            obj[firstType.labels] = [docProperties];
        }
    });

    return obj;
}

function getLayer(name){
    if(layerData[name]){
        return layerData[name]['layer'];
    }
    return null;
}

function getRestrictionLayer(type, name){
    if(restrictionLayerData[type][name]){
        return restrictionLayerData[type][name]['layer'];
    }
    return null;
}

function getLayerData(){
    return retLayerData;
}

function getRestrictionLayerData(){
    return retRestrictionLayerData;
}

function getActiveCounties(){
    return countyMap;
}

function _getSolarColor(value){
    if(value < 5) {return "#e6ffff"}
    else if(value < 10) {return "#beffff"}
    else if(value < 15 ) {return "#64f0ff"}
    else if(value < 20) {return "#32c3ff"}
    else if(value < 25) {return "#008cff"}

    else if(value < 30) {return "#004bff"}
    else if(value < 35) {return "#14aac8"}
    else if(value < 40) {return "#23d7af"}
    else if(value < 45) {return "#3cffa5"}
    else if(value < 50) {return "#5ad25f"}

    else if(value < 55) {return "#7db42d"}
    else if(value < 60) {return "#648c00"}
    else if(value < 65) {return "#3c6400"}
    else if(value < 70) {return "#288700"}
    else if(value < 75) {return "#3cc800"}

    else if(value < 80) {return "#82d700"}
    else if(value < 85) {return "#aae600"}
    else if(value < 90) {return "#cdf514"}
    else if(value < 95) {return "#e1fa3c"}
    else if(value < 100) {return "#f5ff55"}

    else if(value < 105) {return "#ffff91"}
    else if(value < 110) {return "#fff532"}
    else if(value < 115) {return "#ffd200"}
    else if(value < 120) {return "#ffaf00"}
    else if(value < 125) {return "#ff7d00"}

    else if(value < 130) {return "#dc6400"}
    else if(value < 135) {return "#be5a00"}
    else if(value < 140) {return "#aa4600"}
    else if(value < 145) {return "#c83205"}
    else if(value < 150) {return "#ff0f0f"}

    else if(value < 155) {return "#ff4655"}
    else if(value < 160) {return "#ff6496"}
    else if(value < 165) {return "#ff7db4"}
    else if(value < 170) {return "#ff96d2"}
    else if(value < 175) {return "#ffafe6"}

    else if(value < 180) {return "#f5c8ff"}
    else if(value < 185) {return "#f0d7ff"}
    else if(value < 190) {return "#dccdff"}
    else if(value < 195) {return "#cdb9ff"}
    else if(value < 200) {return "#aa8cff"}

    else if(value < 205) {return "#a05fff"}
    else if(value < 210) {return "#7828dc"}
    else if(value < 215) {return "#500aa0"}
    else if(value < 220) {return "#2800a0"}
    else if(value < 225) {return "#280078"}
}

function setDWDColors(dwdValues, filter=[]){
    // TODO: Funktion hier oder woanders besser aufgehoben?
    // TODO: styles nur einmalig anlegen (nicht bei jedem Aufruf)!
    const styles = [
        new Style({
            fill: new Fill({
                color: "#FFFFFF7A",
            }),
            stroke: new Stroke({
                color: "#000000",
                width: 1,
            }),
        }),
        new Style({
            fill: new Fill({
                color: "#ffeb3b7A",
            }),
            stroke: new Stroke({
                color: "#000000",
                width: 1,
            }),
        }),
        new Style({
            fill: new Fill({
                color: "#fb8c007A"
            }),
            stroke: new Stroke({
                color: "#000000",
                width: 1,
            }),
        }),
        new Style({
            fill: new Fill({
                color: "#e539357A"
            }),
            stroke: new Stroke({
                color: "#000000",
                width: 1,
            }),
        }),
        new Style({
            fill: new Fill({
                color: "#880e4f7A"
            }),
            stroke: new Stroke({
                color: "#000000",
                width: 1,
            }),
        }),
        new Style({
            fill: new Fill({
                color: "#005C037A"
            }),
            stroke: new Stroke({
                color: "#000000",
                width: 1,
            }),
        }),
    ];
    const layer = layerData['dwd']['layer'];
    const source = layer.getSource();
    // clear all dwd features
    source.getFeatures().forEach(feature => {
        feature.setStyle(styles[0]);
        feature.set('___currentStyle', styles[0]);
        feature.set('maxLevel', -1);
        feature.set('events', []);
    });
    const activeFeatures = {};
    // set new properties and styles
    dwdValues.forEach(value => {
        const id = value[4];
        const level = value[1] === 0 ? 1 : value[1]; // TODO: level = 0 -> Vorabwarnung, wird im Filter nicht beachtet, das maxLevel < level
        const genType = value[6];
        const feature = source.getFeatureById(id);
        if(feature){
            feature.get('events').push(value);
            if((filter.length === 0 || filter.includes(genType)) && feature.get('maxLevel') < level) {
                feature.setStyle(styles[level]);
                feature.set('___currentStyle', styles[level]);
                feature.set('maxLevel', level);

                if(!activeFeatures[genType]){
                    activeFeatures[genType] = new Set();
                }
                activeFeatures[genType].add(id);
            }
        } else {
            console.error("Kein Feature gefunden!", id);
        }
    });

    countyMap.activeFeatures = activeFeatures;
    sortEventsPerFeature();
    MapData.rehighlightSelectedDwdFeatures();
    setActiveFeatures();
}

function sortEventsPerFeature(){
    const features = layerData['dwd']['layer'].getSource().getFeatures();

    features.forEach(f => {
        const events = f.get('events');
        const eventObj = {};
        events.forEach(e => {
            let type = e[5];
            if(eventObj[type]){
                eventObj[type].push(e);
            } else {
                eventObj[type] = [e];
            }
        });
        f.set('events', eventObj);
        /*
        if(Object.keys(eventObj).length > 0){
            console.log(eventObj);
        }
         */
    });
}

function setActiveFeatures(){
    if(countyMap.activeFeatures == null || countyMap.ids === null){
        return;
    }
    activeFeatureLayer.layer.getSource().clear();
    for( const [key, value] of Object.entries(countyMap.activeFeatures)){
        // value = Set(County IDs)
        value.forEach(id => {

            if(countyMap.ids[id]){
                for(const [fKey, fObj] of Object.entries(countyMap.ids[id])){
                    if(fObj.wTypes.includes(key)){
                        const f = layerData[fObj.category].layer.getSource().getFeatureById(fKey);
                        // feature holen
                        activeFeatureLayer.layer.getSource().addFeature(f);
                    }
                }
            }
        });
    }
    activeFeatureLayer.length.value = activeFeatureLayer.layer.getSource().getFeatures().length;
}

function getActiveFeaturesLayer(){
    // TODO: Name immer wieder mal anders, activeWeatherLayer z.B. in MapLayerControl
    return activeFeatureLayer;
}

function setTextUpdateReceiver(receiver){
    textUpdateReceiver = receiver;
}

async function loadDatesEnvirVis() {
    //let relations = ["withPerson", "withCompany", "gebot", "verbot",  "firstType", "secondType"]
    let relations = ["gebot", "verbot"]
    const query = 'MATCH (d:documents) OPTIONAL MATCH (d)-[r]-(o) ' +
        'WHERE type(r) IN $relations ' +
        'WITH ID(d) as dID, d.Description as des, d.Tags as tags, d.allDates as t, ' +
        'r.start_time as start_time, r.end_time as end_time, ' +
        '{label:labels(o)[0], type:type(r), properties: o} as Neighbor ' +
        'WITH dID, des, tags, t, start_time, end_time, collect(Neighbor) as n ' +
        'RETURN dID, des, tags, t, n, start_time, end_time'
    const result = await Neo4jController.runCypherQuery(query, {"relations": relations});
    let data = []
    result.records.forEach(rec => {
        let n = rec.get('n')
        n = n.filter(x => x.properties).map(x => ( {"name": x.properties.properties.name, "type": x.type, "label": x.label }))
        let t = rec.get('t')
        let dates = (t ? t.split(", ") : [])
        let start_time = rec.get('start_time')
        let end_time = rec.get('end_time')
        if (start_time && end_time) {
            dates = createIntervalDates(start_time, end_time, dates)
        }
        let des = (rec.get('des') ? rec.get('des') : "")
        let tags = (rec.get('tags') ? rec.get('tags') : "")

        dates = dates.map(x => parseDate(x)).sort(function(a, b) {
            if (a.year > b.year) {
                return 1
            } else if (a.year < b.year) {
                return -1
            } else {
                if (a.month > b.month) {
                    return 1
                } else if (a.month < b.month) {
                    return -1
                } else {
                    if (a.day > b.day) {
                        return 1
                    } else if (a.day < b.day) {
                        return -1
                    }
                }
            }
            return 0
        });
        data.push({"doc": toNumber(rec.get('dID')), "description": des, "tags": tags, "dates": dates, "neighbors": n})
    });
    return  data
}

async function loadFeaturesInRegionEnvirVis(region, layerNames) {
    //let relations = ["withPerson", "withCompany", "gebot", "verbot",  "firstType", "secondType"]
    let relations = ["gebot", "verbot"]

    let data = []
    //TODO können aktuell nicht gegen gebote/verbote schneiden sind nicht in datenbanken
    //console.log(layerNames, layerData, restrictionLayerData)
    
    layerNames = Object.keys(layerData)

    //layerNames = layerNames.filter(value => Object.keys(layerData).includes(value))
    
    // only temp for test and dev -> if nothing selected load all data
    if (layerNames.length === 0) {
        return loadDatesEnvirVis()
    }
    // use only selected layers
    for (let iLayer of layerNames){
        if(iLayer === "wegeplanung"){
            iLayer = "wegenetz";
        }
        const result = await Neo4jController.runCypherQuery(
            `CALL spatial.intersects("${iLayer}", "${region}") YIELD node
            WITH node as coord 
            MATCH (coord)-[]-(f:${iLayer})-[]-(d:documents)
            OPTIONAL MATCH (d)-[r]-(o)  
                WHERE type(r) IN $relations 
                WITH ID(d) as dID, d.Description as des, d.Tags as tags, d.allDates as t, 
                r.start_time as start_time, r.end_time as end_time, 
                {label:labels(o)[0], type:type(r), properties: o} as Neighbor 
                WITH dID, des, tags, t, start_time, end_time, collect(Neighbor) as n 
            RETURN dID, des, tags, t, n, start_time, end_time`,  {"relations": relations}
        );

        result.records.forEach(rec => {
            let n = rec.get('n')
            n = n.filter(x => x.properties).map(x => ( {"name": x.properties.properties.name, "type": x.type, "label": x.label }))
            let t = rec.get('t')
            let des = (rec.get('des') ? rec.get('des') : "")
            let tags = (rec.get('tags') ? rec.get('tags') : "")
            let dates = (t ? t.split(", ") : [])
            let start_time = rec.get('start_time')
            let end_time = rec.get('end_time')
            if (start_time && end_time) {
                dates = createIntervalDates(start_time, end_time, dates)
            }
            dates = dates.map(x => parseDate(x)).sort(function(a, b) {
                if (a.year > b.year) {
                    return 1
                } else if (a.year < b.year) {
                    return -1
                } else {
                    if (a.month > b.month) {
                        return 1
                    } else if (a.month < b.month) {
                        return -1
                    } else {
                        if (a.day > b.day) {
                            return 1
                        } else if (a.day < b.day) {
                            return -1
                        }
                    }
                }
                return 0
            });
            data.push({"doc": toNumber(rec.get('dID')), "description": des, "tags": tags, "dates": dates, "neighbors": n})
        });
    }
    return data
}

function createIntervalDates(start, end, dates) {
    //TODO currently only for months and ignore document dates
    //console.log(start, end, dates)
    let startDay = toNumber(start.day)
    let startMonth = toNumber(start.month)
    let startYear = toNumber(start.year)
    //let endDay = toNumber(end.day)
    let endMonth = toNumber(end.month)
    //let endYear = toNumber(end.year)
    let iDates = []
    for (let i = startMonth; i <= endMonth; i++) {
        let date = startDay + "." + i + "." + startYear
        iDates.push(date)
    }

    return iDates
}


export {
    setTextUpdateReceiver,
    loadLayerNames,
    loadRestrictionsNames,
    loadLayerData,
    loadRestrictionsLayerData,
    loadCountyRestrictionsIds,
    loadDocumentIdsWithDate,
    loadPersonsAndCompanies,
    loadDocumentIdsWithPersonAndCompany,
    loadFeaturesByDocumentIDs,
    getLayer,
    getRestrictionLayer,
    getLayerData,
    getRestrictionLayerData,
    getActiveFeaturesLayer,
    getActiveCounties,
    getFeaturesByDocumentIdsWithDate,
    setDWDColors,
    loadIntersectionsCounterForFeature,
    loadIntersectionsForFeatureInCategory,
    loadRestrictions,
    loadDates,
    loadDatesEnvirVis,
    loadFeaturesInRegionEnvirVis,
    loadFeaturesInRegion,
    savePolygonInDB,
}

// TODO: Überall Object als Typ überdenken und eventuell Map verwenden (macht Loops auch einfacher mit (value, key) of map)!