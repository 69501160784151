export default [
  {
    path: '/PDFViewer:pdfID',
    name: 'PDFViewer',
    props: true,
    component: () => import('./PDFViewer'),
    meta: {
    }
  },  
  {
    path: '/',
    name: 'StartPage',
    props: false,
    component: () => import('./StartPage'),
    meta: {
    }
  },    
]
