import neo4j from "neo4j-driver"
import {_neo4j} from "@/OnlyLocalFiles/logins";

const _url = _neo4j.url;
let _port = null;
let _username = null;
let _password = null;
let _driver = null;

function setLoginData(username, password, port){
    _username = username;
    _password = password;
    _port = port;
}

function createDriver(){
    // TODO: Error Handling!
    _driver = neo4j.driver(
        _url + ':' + _port,
        neo4j.auth.basic(_username, _password)
    );
}

async function checkConnectivity(){
    const session = _driver.session();

    let result = false;

    await session.run("RETURN 1")
        .then(function(){
            result = true;
        })
        .catch(function(){
            // Verbindung war nicht erfolgreich!
            result = false;
        })
        .then(() => session.close());

    return result;
}

function createSession(){
    let session = _driver.session();
    // ...
    session.close();
}

function test(username, password){
    if (username.length === 0 || password.length === 0){
        username = _neo4j.username;
        password = _neo4j.password;
    }
    setLoginData(username, password, _neo4j.port);
    createDriver();
}

function runCypherQuery(query, param = {}){
    const session = _driver.session();
    let ret = null;

    return session.run(query, param)
        .then(result => {
            ret = result;
        })
        .catch(error => {
            console.log(error);
            console.log(query);
        })
        .then(() => {
            session.close();
            return ret;
        });
}

export {test, runCypherQuery, checkConnectivity}