const _tmpSolarDataArray = [
    {"date": "2023-07-01T03:00:00Z", "value": 0},
        {"date": "2023-07-01T04:00:00Z", "value": 0},
        {"date": "2023-07-01T05:00:00Z", "value": 57},
        {"date": "2023-07-01T06:00:00Z", "value": 60},
        {"date": "2023-07-01T07:00:00Z", "value": 60},
        {"date": "2023-07-01T08:00:00Z", "value": 60},
        {"date": "2023-07-01T09:00:00Z", "value": 60},
        {"date": "2023-07-01T10:00:00Z", "value": 59},
        {"date": "2023-07-01T11:00:00Z", "value": 42},
        {"date": "2023-07-01T12:00:00Z", "value": 36},
        {"date": "2023-07-01T13:00:00Z", "value": 0},
        {"date": "2023-07-01T14:00:00Z", "value": 0},
        {"date": "2023-07-01T15:00:00Z", "value": 0},
        {"date": "2023-07-01T16:00:00Z", "value": 0},
        {"date": "2023-07-01T17:00:00Z", "value": 0},
        {"date": "2023-07-01T18:00:00Z", "value": 0},
        {"date": "2023-07-01T19:00:00Z", "value": 0},
        {"date": "2023-07-01T20:00:00Z", "value": 0},
        {"date": "2023-07-02T03:00:00Z", "value": 0},
        {"date": "2023-07-02T04:00:00Z", "value": 0},
        {"date": "2023-07-02T05:00:00Z", "value": 0},
        {"date": "2023-07-02T06:00:00Z", "value": 1},
        {"date": "2023-07-02T07:00:00Z", "value": 42},
        {"date": "2023-07-02T08:00:00Z", "value": 24},
        {"date": "2023-07-02T09:00:00Z", "value": 1},
        {"date": "2023-07-02T10:00:00Z", "value": 27},
        {"date": "2023-07-02T11:00:00Z", "value": 25},
        {"date": "2023-07-02T12:00:00Z", "value": 43},
        {"date": "2023-07-02T13:00:00Z", "value": 37},
        {"date": "2023-07-02T14:00:00Z", "value": 45},
        {"date": "2023-07-02T15:00:00Z", "value": 20},
        {"date": "2023-07-02T16:00:00Z", "value": 39},
        {"date": "2023-07-02T17:00:00Z", "value": 27},
        {"date": "2023-07-02T18:00:00Z", "value": 43},
        {"date": "2023-07-02T19:00:00Z", "value": 15},
        {"date": "2023-07-02T20:00:00Z", "value": 0},
        {"date": "2023-07-03T03:00:00Z", "value": 0},
        {"date": "2023-07-03T04:00:00Z", "value": 0},
        {"date": "2023-07-03T05:00:00Z", "value": 1},
        {"date": "2023-07-03T06:00:00Z", "value": 36},
        {"date": "2023-07-03T07:00:00Z", "value": 48},
        {"date": "2023-07-03T08:00:00Z", "value": 46},
        {"date": "2023-07-03T09:00:00Z", "value": 57},
        {"date": "2023-07-03T10:00:00Z", "value": 48},
        {"date": "2023-07-03T11:00:00Z", "value": 49},
        {"date": "2023-07-03T12:00:00Z", "value": 23},
        {"date": "2023-07-03T13:00:00Z", "value": 20},
        {"date": "2023-07-03T14:00:00Z", "value": 13},
        {"date": "2023-07-03T15:00:00Z", "value": 14},
        {"date": "2023-07-03T16:00:00Z", "value": 9},
        {"date": "2023-07-03T17:00:00Z", "value": 5},
        {"date": "2023-07-03T18:00:00Z", "value": 35},
        {"date": "2023-07-03T19:00:00Z", "value": 25},
        {"date": "2023-07-03T20:00:00Z", "value": 0},
        {"date": "2023-07-04T03:00:00Z", "value": 0},
        {"date": "2023-07-04T04:00:00Z", "value": 3},
        {"date": "2023-07-04T05:00:00Z", "value": 60},
        {"date": "2023-07-04T06:00:00Z", "value": 60},
        {"date": "2023-07-04T07:00:00Z", "value": 55},
        {"date": "2023-07-04T08:00:00Z", "value": 55},
        {"date": "2023-07-04T09:00:00Z", "value": 36},
        {"date": "2023-07-04T10:00:00Z", "value": 44},
        {"date": "2023-07-04T11:00:00Z", "value": 56},
        {"date": "2023-07-04T12:00:00Z", "value": 50},
        {"date": "2023-07-04T13:00:00Z", "value": 38},
        {"date": "2023-07-04T14:00:00Z", "value": 56},
        {"date": "2023-07-04T15:00:00Z", "value": 53},
        {"date": "2023-07-04T16:00:00Z", "value": 50},
        {"date": "2023-07-04T17:00:00Z", "value": 57},
        {"date": "2023-07-04T18:00:00Z", "value": 43},
        {"date": "2023-07-04T19:00:00Z", "value": 0},
        {"date": "2023-07-04T20:00:00Z", "value": 0},
        {"date": "2023-07-05T03:00:00Z", "value": 0},
        {"date": "2023-07-05T04:00:00Z", "value": 0},
        {"date": "2023-07-05T05:00:00Z", "value": 2},
        {"date": "2023-07-05T06:00:00Z", "value": 6},
        {"date": "2023-07-05T07:00:00Z", "value": 0},
        {"date": "2023-07-05T08:00:00Z", "value": 0},
        {"date": "2023-07-05T09:00:00Z", "value": 18},
        {"date": "2023-07-05T10:00:00Z", "value": 11},
        {"date": "2023-07-05T11:00:00Z", "value": 0},
        {"date": "2023-07-05T12:00:00Z", "value": 17},
        {"date": "2023-07-05T13:00:00Z", "value": 31},
        {"date": "2023-07-05T14:00:00Z", "value": 59},
        {"date": "2023-07-05T15:00:00Z", "value": 59},
        {"date": "2023-07-05T16:00:00Z", "value": 57},
        {"date": "2023-07-05T17:00:00Z", "value": 47},
        {"date": "2023-07-05T18:00:00Z", "value": 60},
        {"date": "2023-07-05T19:00:00Z", "value": 59},
        {"date": "2023-07-05T20:00:00Z", "value": 6},
        {"date": "2023-07-06T03:00:00Z", "value": 0},
        {"date": "2023-07-06T04:00:00Z", "value": 18},
        {"date": "2023-07-06T05:00:00Z", "value": 60},
        {"date": "2023-07-06T06:00:00Z", "value": 60},
        {"date": "2023-07-06T07:00:00Z", "value": 60},
        {"date": "2023-07-06T08:00:00Z", "value": 60},
        {"date": "2023-07-06T09:00:00Z", "value": 50},
        {"date": "2023-07-06T10:00:00Z", "value": 46},
        {"date": "2023-07-06T11:00:00Z", "value": 35},
        {"date": "2023-07-06T12:00:00Z", "value": 41},
        {"date": "2023-07-06T13:00:00Z", "value": 53},
        {"date": "2023-07-06T14:00:00Z", "value": 50},
        {"date": "2023-07-06T15:00:00Z", "value": 53},
        {"date": "2023-07-06T16:00:00Z", "value": 27},
        {"date": "2023-07-06T17:00:00Z", "value": 56},
        {"date": "2023-07-06T18:00:00Z", "value": 59},
        {"date": "2023-07-06T19:00:00Z", "value": 59},
        {"date": "2023-07-06T20:00:00Z", "value": 6},
        {"date": "2023-07-07T03:00:00Z", "value": 0},
        {"date": "2023-07-07T04:00:00Z", "value": 17},
        {"date": "2023-07-07T05:00:00Z", "value": 60},
        {"date": "2023-07-07T06:00:00Z", "value": 60},
        {"date": "2023-07-07T07:00:00Z", "value": 60},
        {"date": "2023-07-07T08:00:00Z", "value": 60},
        {"date": "2023-07-07T09:00:00Z", "value": 60},
        {"date": "2023-07-07T10:00:00Z", "value": 60},
        {"date": "2023-07-07T11:00:00Z", "value": 60},
        {"date": "2023-07-07T12:00:00Z", "value": 60},
        {"date": "2023-07-07T13:00:00Z", "value": 60},
        {"date": "2023-07-07T14:00:00Z", "value": 60},
        {"date": "2023-07-07T15:00:00Z", "value": 60},
        {"date": "2023-07-07T16:00:00Z", "value": 60},
        {"date": "2023-07-07T17:00:00Z", "value": 60},
        {"date": "2023-07-07T18:00:00Z", "value": 60},
        {"date": "2023-07-07T19:00:00Z", "value": 60},
        {"date": "2023-07-07T20:00:00Z", "value": 6},
        {"date": "2023-07-08T03:00:00Z", "value": 0},
        {"date": "2023-07-08T04:00:00Z", "value": 15},
        {"date": "2023-07-08T05:00:00Z", "value": 60},
        {"date": "2023-07-08T06:00:00Z", "value": 60},
        {"date": "2023-07-08T07:00:00Z", "value": 60},
        {"date": "2023-07-08T08:00:00Z", "value": 60},
        {"date": "2023-07-08T09:00:00Z", "value": 60},
        {"date": "2023-07-08T10:00:00Z", "value": 60},
        {"date": "2023-07-08T11:00:00Z", "value": 60},
        {"date": "2023-07-08T12:00:00Z", "value": 60},
        {"date": "2023-07-08T13:00:00Z", "value": 60},
        {"date": "2023-07-08T14:00:00Z", "value": 60},
        {"date": "2023-07-08T15:00:00Z", "value": 60},
        {"date": "2023-07-08T16:00:00Z", "value": 60},
        {"date": "2023-07-08T17:00:00Z", "value": 60},
        {"date": "2023-07-08T18:00:00Z", "value": 60},
        {"date": "2023-07-08T19:00:00Z", "value": 60},
        {"date": "2023-07-08T20:00:00Z", "value": 3},
        {"date": "2023-07-09T03:00:00Z", "value": 0},
        {"date": "2023-07-09T04:00:00Z", "value": 14},
        {"date": "2023-07-09T05:00:00Z", "value": 60},
        {"date": "2023-07-09T06:00:00Z", "value": 60},
        {"date": "2023-07-09T07:00:00Z", "value": 60},
        {"date": "2023-07-09T08:00:00Z", "value": 60},
        {"date": "2023-07-09T09:00:00Z", "value": 60},
        {"date": "2023-07-09T10:00:00Z", "value": 60},
        {"date": "2023-07-09T11:00:00Z", "value": 60},
        {"date": "2023-07-09T12:00:00Z", "value": 60},
        {"date": "2023-07-09T13:00:00Z", "value": 60},
        {"date": "2023-07-09T14:00:00Z", "value": 60},
        {"date": "2023-07-09T15:00:00Z", "value": 60},
        {"date": "2023-07-09T16:00:00Z", "value": 58},
        {"date": "2023-07-09T17:00:00Z", "value": 56},
        {"date": "2023-07-09T18:00:00Z", "value": 37},
        {"date": "2023-07-09T19:00:00Z", "value": 0},
        {"date": "2023-07-09T20:00:00Z", "value": 0},
        {"date": "2023-07-10T03:00:00Z", "value": 0},
        {"date": "2023-07-10T04:00:00Z", "value": 0},
        {"date": "2023-07-10T05:00:00Z", "value": 0},
        {"date": "2023-07-10T06:00:00Z", "value": 0},
        {"date": "2023-07-10T07:00:00Z", "value": 39},
        {"date": "2023-07-10T08:00:00Z", "value": 60},
        {"date": "2023-07-10T09:00:00Z", "value": 60},
        {"date": "2023-07-10T10:00:00Z", "value": 58},
        {"date": "2023-07-10T11:00:00Z", "value": 57},
        {"date": "2023-07-10T12:00:00Z", "value": 46},
        {"date": "2023-07-10T13:00:00Z", "value": 24},
        {"date": "2023-07-10T14:00:00Z", "value": 2},
        {"date": "2023-07-10T15:00:00Z", "value": 48},
        {"date": "2023-07-10T16:00:00Z", "value": 40},
        {"date": "2023-07-10T17:00:00Z", "value": 18},
        {"date": "2023-07-10T18:00:00Z", "value": 50},
        {"date": "2023-07-10T19:00:00Z", "value": 60},
        {"date": "2023-07-10T20:00:00Z", "value": 4},
        {"date": "2023-07-11T03:00:00Z", "value": 0},
        {"date": "2023-07-11T04:00:00Z", "value": 13},
        {"date": "2023-07-11T05:00:00Z", "value": 49},
        {"date": "2023-07-11T06:00:00Z", "value": 60},
        {"date": "2023-07-11T07:00:00Z", "value": 60},
        {"date": "2023-07-11T08:00:00Z", "value": 60},
        {"date": "2023-07-11T09:00:00Z", "value": 60},
        {"date": "2023-07-11T10:00:00Z", "value": 60},
        {"date": "2023-07-11T11:00:00Z", "value": 60},
        {"date": "2023-07-11T12:00:00Z", "value": 60},
        {"date": "2023-07-11T13:00:00Z", "value": 60},
        {"date": "2023-07-11T14:00:00Z", "value": 60},
        {"date": "2023-07-11T15:00:00Z", "value": 59},
        {"date": "2023-07-11T16:00:00Z", "value": 47},
        {"date": "2023-07-11T17:00:00Z", "value": 59},
        {"date": "2023-07-11T18:00:00Z", "value": 55},
        {"date": "2023-07-11T19:00:00Z", "value": 36},
        {"date": "2023-07-11T20:00:00Z", "value": 0},
        {"date": "2023-07-12T03:00:00Z", "value": 0},
        {"date": "2023-07-12T04:00:00Z", "value": 0},
        {"date": "2023-07-12T05:00:00Z", "value": 0},
        {"date": "2023-07-12T06:00:00Z", "value": 21},
        {"date": "2023-07-12T07:00:00Z", "value": 0},
        {"date": "2023-07-12T13:00:00Z", "value": 48},
        {"date": "2023-07-12T14:00:00Z", "value": 48},
        {"date": "2023-07-12T15:00:00Z", "value": 58},
        {"date": "2023-07-12T16:00:00Z", "value": 58},
        {"date": "2023-07-12T17:00:00Z", "value": 60},
        {"date": "2023-07-12T18:00:00Z", "value": 59},
        {"date": "2023-07-12T19:00:00Z", "value": 42},
        {"date": "2023-07-12T20:00:00Z", "value": 0},
        {"date": "2023-07-13T03:00:00Z", "value": 0},
        {"date": "2023-07-13T04:00:00Z", "value": 1},
        {"date": "2023-07-13T05:00:00Z", "value": 39},
        {"date": "2023-07-13T06:00:00Z", "value": 59},
        {"date": "2023-07-13T07:00:00Z", "value": 60},
        {"date": "2023-07-13T08:00:00Z", "value": 59},
        {"date": "2023-07-13T09:00:00Z", "value": 56},
        {"date": "2023-07-13T10:00:00Z", "value": 18},
        {"date": "2023-07-13T11:00:00Z", "value": 25},
        {"date": "2023-07-13T12:00:00Z", "value": 25},
        {"date": "2023-07-13T13:00:00Z", "value": 57},
        {"date": "2023-07-13T14:00:00Z", "value": 57},
        {"date": "2023-07-13T15:00:00Z", "value": 43},
        {"date": "2023-07-13T16:00:00Z", "value": 14},
        {"date": "2023-07-13T17:00:00Z", "value": 14},
        {"date": "2023-07-13T18:00:00Z", "value": 0},
        {"date": "2023-07-13T19:00:00Z", "value": 33},
        {"date": "2023-07-13T20:00:00Z", "value": 0},
        {"date": "2023-07-14T03:00:00Z", "value": 0},
        {"date": "2023-07-14T04:00:00Z", "value": 0},
        {"date": "2023-07-14T05:00:00Z", "value": 41},
        {"date": "2023-07-14T06:00:00Z", "value": 48},
        {"date": "2023-07-14T07:00:00Z", "value": 60},
        {"date": "2023-07-14T08:00:00Z", "value": 60},
        {"date": "2023-07-14T09:00:00Z", "value": 60},
        {"date": "2023-07-14T10:00:00Z", "value": 60},
        {"date": "2023-07-14T11:00:00Z", "value": 45},
        {"date": "2023-07-14T12:00:00Z", "value": 36},
        {"date": "2023-07-14T13:00:00Z", "value": 50},
        {"date": "2023-07-14T14:00:00Z", "value": 14},
        {"date": "2023-07-14T15:00:00Z", "value": 23},
        {"date": "2023-07-14T16:00:00Z", "value": 11},
        {"date": "2023-07-14T17:00:00Z", "value": 25},
        {"date": "2023-07-14T18:00:00Z", "value": 58},
        {"date": "2023-07-14T19:00:00Z", "value": 52},
        {"date": "2023-07-14T20:00:00Z", "value": 0},
        {"date": "2023-07-15T03:00:00Z", "value": 0},
        {"date": "2023-07-15T04:00:00Z", "value": 11},
        {"date": "2023-07-15T05:00:00Z", "value": 57},
        {"date": "2023-07-15T06:00:00Z", "value": 60},
        {"date": "2023-07-15T07:00:00Z", "value": 58},
        {"date": "2023-07-15T08:00:00Z", "value": 60},
        {"date": "2023-07-15T09:00:00Z", "value": 60},
        {"date": "2023-07-15T10:00:00Z", "value": 60},
        {"date": "2023-07-15T11:00:00Z", "value": 60},
        {"date": "2023-07-15T12:00:00Z", "value": 56},
        {"date": "2023-07-15T13:00:00Z", "value": 43},
        {"date": "2023-07-15T14:00:00Z", "value": 31},
        {"date": "2023-07-15T15:00:00Z", "value": 14},
        {"date": "2023-07-15T16:00:00Z", "value": 5},
        {"date": "2023-07-15T17:00:00Z", "value": 0},
        {"date": "2023-07-15T18:00:00Z", "value": 2},
        {"date": "2023-07-15T19:00:00Z", "value": 7},
        {"date": "2023-07-15T20:00:00Z", "value": 0},
        {"date": "2023-07-16T03:00:00Z", "value": 0},
        {"date": "2023-07-16T04:00:00Z", "value": 0},
        {"date": "2023-07-16T05:00:00Z", "value": 8},
        {"date": "2023-07-16T06:00:00Z", "value": 0},
        {"date": "2023-07-16T07:00:00Z", "value": 59},
        {"date": "2023-07-16T08:00:00Z", "value": 56},
        {"date": "2023-07-16T09:00:00Z", "value": 56},
        {"date": "2023-07-16T10:00:00Z", "value": 51},
        {"date": "2023-07-16T11:00:00Z", "value": 60},
        {"date": "2023-07-16T12:00:00Z", "value": 53},
        {"date": "2023-07-16T13:00:00Z", "value": 51},
        {"date": "2023-07-16T14:00:00Z", "value": 52},
        {"date": "2023-07-16T15:00:00Z", "value": 59},
        {"date": "2023-07-16T16:00:00Z", "value": 60},
        {"date": "2023-07-16T17:00:00Z", "value": 60},
        {"date": "2023-07-16T18:00:00Z", "value": 60},
        {"date": "2023-07-16T19:00:00Z", "value": 43},
        {"date": "2023-07-16T20:00:00Z", "value": 0},
        {"date": "2023-07-17T03:00:00Z", "value": 0},
        {"date": "2023-07-17T04:00:00Z", "value": 0},
        {"date": "2023-07-17T05:00:00Z", "value": 60},
        {"date": "2023-07-17T06:00:00Z", "value": 52},
        {"date": "2023-07-17T07:00:00Z", "value": 60},
        {"date": "2023-07-17T08:00:00Z", "value": 60},
        {"date": "2023-07-17T09:00:00Z", "value": 60},
        {"date": "2023-07-17T10:00:00Z", "value": 58},
        {"date": "2023-07-17T11:00:00Z", "value": 59},
        {"date": "2023-07-17T12:00:00Z", "value": 53},
        {"date": "2023-07-17T13:00:00Z", "value": 27},
        {"date": "2023-07-17T14:00:00Z", "value": 50},
        {"date": "2023-07-17T15:00:00Z", "value": 44},
        {"date": "2023-07-17T16:00:00Z", "value": 44},
        {"date": "2023-07-17T17:00:00Z", "value": 34},
        {"date": "2023-07-17T18:00:00Z", "value": 46},
        {"date": "2023-07-17T19:00:00Z", "value": 0},
        {"date": "2023-07-17T20:00:00Z", "value": 0},
        {"date": "2023-07-18T03:00:00Z", "value": 0},
        {"date": "2023-07-18T04:00:00Z", "value": 11},
        {"date": "2023-07-18T05:00:00Z", "value": 41},
        {"date": "2023-07-18T06:00:00Z", "value": 52},
        {"date": "2023-07-18T07:00:00Z", "value": 60},
        {"date": "2023-07-18T08:00:00Z", "value": 60},
        {"date": "2023-07-18T09:00:00Z", "value": 60},
        {"date": "2023-07-18T10:00:00Z", "value": 60},
        {"date": "2023-07-18T11:00:00Z", "value": 60},
        {"date": "2023-07-18T12:00:00Z", "value": 60},
        {"date": "2023-07-18T13:00:00Z", "value": 35},
        {"date": "2023-07-18T14:00:00Z", "value": 17},
        {"date": "2023-07-18T15:00:00Z", "value": 45},
        {"date": "2023-07-18T16:00:00Z", "value": 51},
        {"date": "2023-07-18T17:00:00Z", "value": 60},
        {"date": "2023-07-18T18:00:00Z", "value": 60},
        {"date": "2023-07-18T19:00:00Z", "value": 53},
        {"date": "2023-07-18T20:00:00Z", "value": 0},
        {"date": "2023-07-19T03:00:00Z", "value": 0},
        {"date": "2023-07-19T04:00:00Z", "value": 0},
        {"date": "2023-07-19T05:00:00Z", "value": 44},
        {"date": "2023-07-19T06:00:00Z", "value": 56},
        {"date": "2023-07-19T07:00:00Z", "value": 48},
        {"date": "2023-07-19T08:00:00Z", "value": 60},
        {"date": "2023-07-19T09:00:00Z", "value": 26},
        {"date": "2023-07-19T10:00:00Z", "value": 30},
        {"date": "2023-07-19T11:00:00Z", "value": 60},
        {"date": "2023-07-19T12:00:00Z", "value": 52},
        {"date": "2023-07-19T13:00:00Z", "value": 2},
        {"date": "2023-07-19T14:00:00Z", "value": 4},
        {"date": "2023-07-19T15:00:00Z", "value": 0},
        {"date": "2023-07-19T16:00:00Z", "value": 0},
        {"date": "2023-07-19T17:00:00Z", "value": 6},
        {"date": "2023-07-19T18:00:00Z", "value": 0},
        {"date": "2023-07-19T19:00:00Z", "value": 0},
        {"date": "2023-07-19T20:00:00Z", "value": 0},
        {"date": "2023-07-20T03:00:00Z", "value": 0},
        {"date": "2023-07-20T04:00:00Z", "value": 0},
        {"date": "2023-07-20T05:00:00Z", "value": 0},
        {"date": "2023-07-20T06:00:00Z", "value": 3},
        {"date": "2023-07-20T07:00:00Z", "value": 25},
        {"date": "2023-07-20T08:00:00Z", "value": 7},
        {"date": "2023-07-20T09:00:00Z", "value": 28},
        {"date": "2023-07-20T10:00:00Z", "value": 7},
        {"date": "2023-07-20T11:00:00Z", "value": 0},
        {"date": "2023-07-20T12:00:00Z", "value": 8},
        {"date": "2023-07-20T13:00:00Z", "value": 11},
        {"date": "2023-07-20T14:00:00Z", "value": 13},
        {"date": "2023-07-20T15:00:00Z", "value": 40},
        {"date": "2023-07-20T16:00:00Z", "value": 54},
        {"date": "2023-07-20T17:00:00Z", "value": 45},
        {"date": "2023-07-20T18:00:00Z", "value": 38},
        {"date": "2023-07-20T19:00:00Z", "value": 58},
        {"date": "2023-07-20T20:00:00Z", "value": 0},
        {"date": "2023-07-21T03:00:00Z", "value": 0},
        {"date": "2023-07-21T04:00:00Z", "value": 0},
        {"date": "2023-07-21T05:00:00Z", "value": 16},
        {"date": "2023-07-21T06:00:00Z", "value": 38},
        {"date": "2023-07-21T07:00:00Z", "value": 11},
        {"date": "2023-07-21T08:00:00Z", "value": 0},
        {"date": "2023-07-21T09:00:00Z", "value": 0},
        {"date": "2023-07-21T10:00:00Z", "value": 0},
        {"date": "2023-07-21T11:00:00Z", "value": 0},
        {"date": "2023-07-21T12:00:00Z", "value": 0},
        {"date": "2023-07-21T13:00:00Z", "value": 9},
        {"date": "2023-07-21T14:00:00Z", "value": 60},
        {"date": "2023-07-21T15:00:00Z", "value": 22},
        {"date": "2023-07-21T16:00:00Z", "value": 0},
        {"date": "2023-07-21T17:00:00Z", "value": 0},
        {"date": "2023-07-21T18:00:00Z", "value": 0},
        {"date": "2023-07-21T19:00:00Z", "value": 1},
        {"date": "2023-07-21T20:00:00Z", "value": 0},
        {"date": "2023-07-22T03:00:00Z", "value": 0},
        {"date": "2023-07-22T04:00:00Z", "value": 0},
        {"date": "2023-07-22T05:00:00Z", "value": 48},
        {"date": "2023-07-22T06:00:00Z", "value": 60},
        {"date": "2023-07-22T07:00:00Z", "value": 60},
        {"date": "2023-07-22T08:00:00Z", "value": 60},
        {"date": "2023-07-22T09:00:00Z", "value": 59},
        {"date": "2023-07-22T10:00:00Z", "value": 46},
        {"date": "2023-07-22T11:00:00Z", "value": 10},
        {"date": "2023-07-22T12:00:00Z", "value": 20},
        {"date": "2023-07-22T13:00:00Z", "value": 4},
        {"date": "2023-07-22T14:00:00Z", "value": 29},
        {"date": "2023-07-22T15:00:00Z", "value": 31},
        {"date": "2023-07-22T16:00:00Z", "value": 37},
        {"date": "2023-07-22T17:00:00Z", "value": 0},
        {"date": "2023-07-22T18:00:00Z", "value": 12},
        {"date": "2023-07-22T19:00:00Z", "value": 6},
        {"date": "2023-07-22T20:00:00Z", "value": 0},
        {"date": "2023-07-23T03:00:00Z", "value": 0},
        {"date": "2023-07-23T04:00:00Z", "value": 0},
        {"date": "2023-07-23T05:00:00Z", "value": 0},
        {"date": "2023-07-23T06:00:00Z", "value": 0},
        {"date": "2023-07-23T07:00:00Z", "value": 0},
        {"date": "2023-07-23T08:00:00Z", "value": 13},
        {"date": "2023-07-23T09:00:00Z", "value": 37},
        {"date": "2023-07-23T10:00:00Z", "value": 34},
        {"date": "2023-07-23T11:00:00Z", "value": 41},
        {"date": "2023-07-23T12:00:00Z", "value": 17},
        {"date": "2023-07-23T13:00:00Z", "value": 0},
        {"date": "2023-07-23T14:00:00Z", "value": 0},
        {"date": "2023-07-23T15:00:00Z", "value": 0},
        {"date": "2023-07-23T16:00:00Z", "value": 0},
        {"date": "2023-07-23T17:00:00Z", "value": 0},
        {"date": "2023-07-23T18:00:00Z", "value": 0},
        {"date": "2023-07-23T19:00:00Z", "value": 6},
        {"date": "2023-07-23T20:00:00Z", "value": 0},
        {"date": "2023-07-24T03:00:00Z", "value": 0},
        {"date": "2023-07-24T04:00:00Z", "value": 0},
        {"date": "2023-07-24T05:00:00Z", "value": 29},
        {"date": "2023-07-24T06:00:00Z", "value": 56},
        {"date": "2023-07-24T07:00:00Z", "value": 60},
        {"date": "2023-07-24T08:00:00Z", "value": 27},
        {"date": "2023-07-24T09:00:00Z", "value": 28},
        {"date": "2023-07-24T10:00:00Z", "value": 19},
        {"date": "2023-07-24T11:00:00Z", "value": 56},
        {"date": "2023-07-24T12:00:00Z", "value": 45},
        {"date": "2023-07-24T13:00:00Z", "value": 22},
        {"date": "2023-07-24T14:00:00Z", "value": 0},
        {"date": "2023-07-24T15:00:00Z", "value": 8},
        {"date": "2023-07-24T16:00:00Z", "value": 11},
        {"date": "2023-07-24T17:00:00Z", "value": 19},
        {"date": "2023-07-24T18:00:00Z", "value": 0},
        {"date": "2023-07-24T19:00:00Z", "value": 3},
        {"date": "2023-07-24T20:00:00Z", "value": 0},
        {"date": "2023-07-25T03:00:00Z", "value": 0},
        {"date": "2023-07-25T04:00:00Z", "value": 0},
        {"date": "2023-07-25T05:00:00Z", "value": 0},
        {"date": "2023-07-25T06:00:00Z", "value": 0},
        {"date": "2023-07-25T07:00:00Z", "value": 7},
        {"date": "2023-07-25T08:00:00Z", "value": 17},
        {"date": "2023-07-25T09:00:00Z", "value": 0},
        {"date": "2023-07-25T10:00:00Z", "value": 15},
        {"date": "2023-07-25T11:00:00Z", "value": 21},
        {"date": "2023-07-25T12:00:00Z", "value": 0},
        {"date": "2023-07-25T13:00:00Z", "value": 1},
        {"date": "2023-07-25T14:00:00Z", "value": 0},
        {"date": "2023-07-25T15:00:00Z", "value": 0},
        {"date": "2023-07-25T16:00:00Z", "value": 0},
        {"date": "2023-07-25T17:00:00Z", "value": 0},
        {"date": "2023-07-25T18:00:00Z", "value": 0},
        {"date": "2023-07-25T19:00:00Z", "value": 0},
        {"date": "2023-07-25T20:00:00Z", "value": 0},
        {"date": "2023-07-26T03:00:00Z", "value": 0},
        {"date": "2023-07-26T04:00:00Z", "value": 0},
        {"date": "2023-07-26T05:00:00Z", "value": 4},
        {"date": "2023-07-26T06:00:00Z", "value": 3},
        {"date": "2023-07-26T07:00:00Z", "value": 5},
        {"date": "2023-07-26T08:00:00Z", "value": 56},
        {"date": "2023-07-26T09:00:00Z", "value": 58},
        {"date": "2023-07-26T10:00:00Z", "value": 27},
        {"date": "2023-07-26T11:00:00Z", "value": 17},
        {"date": "2023-07-26T12:00:00Z", "value": 58},
        {"date": "2023-07-26T13:00:00Z", "value": 58},
        {"date": "2023-07-26T14:00:00Z", "value": 20},
        {"date": "2023-07-26T15:00:00Z", "value": 36},
        {"date": "2023-07-26T16:00:00Z", "value": 46},
        {"date": "2023-07-26T17:00:00Z", "value": 18},
        {"date": "2023-07-26T18:00:00Z", "value": 0},
        {"date": "2023-07-26T19:00:00Z", "value": 5},
        {"date": "2023-07-26T20:00:00Z", "value": 0},
        {"date": "2023-07-27T03:00:00Z", "value": 0},
        {"date": "2023-07-27T04:00:00Z", "value": 0},
        {"date": "2023-07-27T05:00:00Z", "value": 35},
        {"date": "2023-07-27T06:00:00Z", "value": 3},
        {"date": "2023-07-27T07:00:00Z", "value": 7},
        {"date": "2023-07-27T08:00:00Z", "value": 18},
        {"date": "2023-07-27T09:00:00Z", "value": 8},
        {"date": "2023-07-27T10:00:00Z", "value": 0},
        {"date": "2023-07-27T11:00:00Z", "value": 0},
        {"date": "2023-07-27T12:00:00Z", "value": 0},
        {"date": "2023-07-27T13:00:00Z", "value": 0},
        {"date": "2023-07-27T14:00:00Z", "value": 0},
        {"date": "2023-07-27T15:00:00Z", "value": 0},
        {"date": "2023-07-27T16:00:00Z", "value": 0},
        {"date": "2023-07-27T17:00:00Z", "value": 0},
        {"date": "2023-07-27T18:00:00Z", "value": 0},
        {"date": "2023-07-27T19:00:00Z", "value": 0},
        {"date": "2023-07-27T20:00:00Z", "value": 0},
        {"date": "2023-07-28T03:00:00Z", "value": 0},
        {"date": "2023-07-28T04:00:00Z", "value": 0},
        {"date": "2023-07-28T05:00:00Z", "value": 1},
        {"date": "2023-07-28T06:00:00Z", "value": 2},
        {"date": "2023-07-28T07:00:00Z", "value": 22},
        {"date": "2023-07-28T08:00:00Z", "value": 42},
        {"date": "2023-07-28T09:00:00Z", "value": 40},
        {"date": "2023-07-28T10:00:00Z", "value": 28},
        {"date": "2023-07-28T11:00:00Z", "value": 21},
        {"date": "2023-07-28T12:00:00Z", "value": 19},
        {"date": "2023-07-28T13:00:00Z", "value": 24},
        {"date": "2023-07-28T14:00:00Z", "value": 28},
        {"date": "2023-07-28T15:00:00Z", "value": 30},
        {"date": "2023-07-28T16:00:00Z", "value": 33},
        {"date": "2023-07-28T17:00:00Z", "value": 36},
        {"date": "2023-07-28T18:00:00Z", "value": 2},
        {"date": "2023-07-28T19:00:00Z", "value": 0},
        {"date": "2023-07-28T20:00:00Z", "value": 0},
        {"date": "2023-07-29T03:00:00Z", "value": 0},
        {"date": "2023-07-29T04:00:00Z", "value": 0},
        {"date": "2023-07-29T05:00:00Z", "value": 51},
        {"date": "2023-07-29T06:00:00Z", "value": 60},
        {"date": "2023-07-29T07:00:00Z", "value": 59},
        {"date": "2023-07-29T08:00:00Z", "value": 17},
        {"date": "2023-07-29T09:00:00Z", "value": 5},
        {"date": "2023-07-29T10:00:00Z", "value": 6},
        {"date": "2023-07-29T11:00:00Z", "value": 1},
        {"date": "2023-07-29T12:00:00Z", "value": 27},
        {"date": "2023-07-29T13:00:00Z", "value": 9},
        {"date": "2023-07-29T14:00:00Z", "value": 59},
        {"date": "2023-07-29T15:00:00Z", "value": 28},
        {"date": "2023-07-29T16:00:00Z", "value": 2},
        {"date": "2023-07-29T17:00:00Z", "value": 17},
        {"date": "2023-07-29T18:00:00Z", "value": 13},
        {"date": "2023-07-29T19:00:00Z", "value": 27},
        {"date": "2023-07-29T20:00:00Z", "value": 0},
        {"date": "2023-07-30T03:00:00Z", "value": 0},
        {"date": "2023-07-30T04:00:00Z", "value": 0},
        {"date": "2023-07-30T05:00:00Z", "value": 0},
        {"date": "2023-07-30T06:00:00Z", "value": 22},
        {"date": "2023-07-30T07:00:00Z", "value": 47},
        {"date": "2023-07-30T08:00:00Z", "value": 54},
        {"date": "2023-07-30T09:00:00Z", "value": 33},
        {"date": "2023-07-30T10:00:00Z", "value": 47},
        {"date": "2023-07-30T11:00:00Z", "value": 53},
        {"date": "2023-07-30T12:00:00Z", "value": 18},
        {"date": "2023-07-30T13:00:00Z", "value": 0},
        {"date": "2023-07-30T14:00:00Z", "value": 0},
        {"date": "2023-07-30T15:00:00Z", "value": 3},
        {"date": "2023-07-30T16:00:00Z", "value": 0},
        {"date": "2023-07-30T17:00:00Z", "value": 1},
        {"date": "2023-07-30T18:00:00Z", "value": 42},
        {"date": "2023-07-30T19:00:00Z", "value": 0},
        {"date": "2023-07-30T20:00:00Z", "value": 0},
        {"date": "2023-07-31T03:00:00Z", "value": 0},
        {"date": "2023-07-31T04:00:00Z", "value": 0},
        {"date": "2023-07-31T05:00:00Z", "value": 0},
        {"date": "2023-07-31T06:00:00Z", "value": 0},
        {"date": "2023-07-31T07:00:00Z", "value": 0},
        {"date": "2023-07-31T08:00:00Z", "value": 0},
        {"date": "2023-07-31T09:00:00Z", "value": 0},
        {"date": "2023-07-31T10:00:00Z", "value": 0},
        {"date": "2023-07-31T11:00:00Z", "value": 3},
        {"date": "2023-07-31T12:00:00Z", "value": 12},
        {"date": "2023-07-31T13:00:00Z", "value": 22},
        {"date": "2023-07-31T14:00:00Z", "value": 10},
        {"date": "2023-07-31T15:00:00Z", "value": 3},
        {"date": "2023-07-31T16:00:00Z", "value": 0},
        {"date": "2023-07-31T17:00:00Z", "value": 0},
        {"date": "2023-07-31T18:00:00Z", "value": 0},
        {"date": "2023-07-31T19:00:00Z", "value": 0},
        {"date": "2023-07-31T20:00:00Z", "value": 0}
    ]

export default _tmpSolarDataArray