import * as Senergy from "@/modules/Senergy";
import * as CypherQueries from "@/modules/CypherQueries";
import _tmpSolarDataArray from "./tmpSolarData";

//https://ui.senergy.infai.org/exports/details/9658e52b-5295-4188-8eba-17df2a77b465

const state = {
    dwdData: null,
    pegelData: null,
    niederschlagData: null,
    ubaData: null,
    wetterData: null,
    solarData: null,
    currentFilters: [],
    senergyReady: false,
    sensorQuery: "https://api.senergy.infai.org/db/v3-sardine/queries",
    niederschlagID: "24285521-75b8-4454-bec3-e0997623e52e",
    unwetterwarnungenID: "06a0d241-a43b-4bf4-a10b-9cda212e2a0b",
    pegelständeID: "e0c68f27-b378-4761-84a1-719c089e42c0",
    ubaID: "3ebc499b-99e9-43fb-b309-d1b6b215926e",
    wetterID: "e3ab92d7-b4c0-497f-ace3-01418e83da62",
    //pegelKeys: ["value", "unit", "station_id", "name", "status", "tendency", "lat", "long", "waterbody", "predicted_for"],
    pegelKeys: ["value", "unit", "station_id", "name", "lat", "long", "waterbody"],
    niederschlagKeys: ["value", "unit", "precision", "projection", "lat", "lat_tag", "long", "long_tag", "lat_top_right", "long_top_right"],
    //wetterKeys: ["pressure","pressure_unit", "temperature_2m","temperature_2m_unit","temperature_5cm","temperature_5cm_unit","rel_humidity_2m","rel_humidity_2m_unit", "dew_point_2m","dew_point_2m_unit","quality_level", "lat", "long","name","id", "height"],
    wetterKeys: ["pressure","temperature_2m","temperature_5cm","rel_humidity_2m","dew_point_2m", "lat", "long","name","id", "height"],
    //ubaKeys: ["O3_value", "O3_unit", "O3_measurement_pretty", "PM10_value", "PM10_unit", "PM10_measurement_pretty", "CO_value", "CO_unit", "CO_measurement_pretty", "SO2_value", "SO2_unit", "SO2_measurement_pretty", "NO2_value", "NO2_unit", "NO2_measurement_pretty", "PM10PB_value", "PM10PB_unit", "PM10PB_measurement_pretty", "PM10BAP_value", "PM10BAP_unit", "PM10BAP_measurement_pretty", "CHB_value", "CHB_unit", "CHB_measurement_pretty", "PM2_value", "PM2_unit", "PM2_measurement_pretty", "PM10AS_value", "PM10AS_unit", "PM10AS_measurement_pretty", "PM10CD_value", "PM10CD_unit", "PM10CD_measurement_pretty", "PM10NI_value", "PM10NI_unit", "PM10NI_measurement_pretty", "station_id", "station_name", "lat", "long", "city", "state"],
    ubaKeys: ["O3_value", "PM10_value", "CO_value", "SO2_value",  "NO2_value", "PM10PB_value","PM10BAP_value", "CHB_value", "PM2_value", "PM10AS_value", "PM10CD_value", "PM10NI_value", "station_id", "station_name", "lat", "long", "city", "state"],
};

const getters = {
    getDwdData: state => state.dwdData,
    getCurrentFilters: state => state.currentFilters,
    isSenergyReady: state => state.senergyReady,
    getUnwetterID: state => state.unwetterwarnungenID,
    getNiederschlagID: state => state.niederschlagID,
    getPegelID: state => state.pegelständeID,
    getUBAID: state => state.ubaID,
    getSensorQuery: state => state.sensorQuery,
    getPegelData: state => state.pegelData,
    getNiederschlagData: state => state.niederschlagData,
    getUBAData: state => state.ubaData,
    getWetterData: state => state.wetterData,
    getUBAKeys: state => state.ubaKeys,
    getWetterKeys: state => state.wetterKeys,
    getNiederschlagKeys: state => state.niederschlagKeys,
    getPegelKeys: state => state.pegelKeys,
    getSolarData: state => state.solarData,
};


const mutations = {
    /* synchronously */
    setDwdData(state, data){
        state.dwdData = data;
    },
    setPegelData(state, data){
        state.pegelData = data;
    },
    setUBAData(state, data){
        state.ubaData = data;
    },
    setNiederschlagData(state, data){
        state.niederschlagData = data;
    },
    setSolarData(state, data){
        state.solarData = data;
    },
    setWetterData(state, data){
        state.wetterData = data;
    },                
    setCurrentFilters(state, currentFilters){
        state.currentFilters = currentFilters;
    },
    setConnectionReady(state){
        state.senergyReady = true;
    }
};

const actions = {
    /* asynchronously */
    /* Example:
    incrementAsync({commit}){
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                commit('increment');
                resolve();
            }, 1000)
        });
    },
     */
    async setConnection({commit}, data){
        // TODO: errorFun?
        const check = await Senergy.createConnection(data.username, data.password, (e) => console.log(e));
        if(check) {
            commit('setConnectionReady');
        }
    },
    loadWarningsForDate(context, date){
        return Senergy.getWarningsForDate(date).then(data => {
            CypherQueries.setDWDColors(data, context.getters.getCurrentFilters);
            context.commit('setDwdData', data);
        });
    },
    loadPegelForDate(context, date) {
        return Senergy.getPegelForDate(date).then(data => {
            context.commit('setPegelData', data);
        });
    },
    loadUBAForDate(context, date) {
        return Senergy.getUBAForDate(date).then(data => {
            context.commit('setUBAData', data);
        });
    },
    loadNiederschlagForDate(context, date) {
        return Senergy.getNiederschlagForDate(date).then(data => {
            //CypherQueries.setDWDColors(data, context.getters.getCurrentFilters);
            context.commit('setNiederschlagData', data);
        });
    },  
    loadWetterForDate(context, date) {
        return Senergy.getWetterForDate(date).then(data => {
            context.commit('setWetterData', data);
        });
    },
    loadSolarForDate(context, date){
        const start = new Date(date.first_date);
        const end = new Date(date.last_date + "T23:59:59");
        let data = [];
        _tmpSolarDataArray.forEach(entry => {
            const date = new Date(entry.date);
            if(date >= start && date <= end){
                data.push({"time": entry.date, "value": entry.value});
            }
        });
        context.commit('setSolarData', data);
    },
    setCurrentFilters(context, currentFilters){
        context.commit("setCurrentFilters", currentFilters);
        CypherQueries.setDWDColors(context.getters.getDwdData, currentFilters);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};