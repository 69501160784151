const state = {
	//TODO use Object for path, text and number to prevent duplicate storages? -> could be problematic with text and number for the same pdf
	pdfPath: [],
	highlightText: [],
	pageNum: [],
	drawMode: false,
	// if true intersect otherwise, polygon should contain
	polygonIntersect: true,
}

const getters = {
	getDrawMode: state => state.drawMode,
	getPolygonIntersect: state => state.polygonIntersect
}

const actions = {

}

const mutations = {
	addPDFPath(state, path) {
		state.pdfPath.push(path)
	},
	addHighlightText(state, text) {
		state.highlightText.push(text)
	},
	addPageNumber(state, num) {
		state.pageNum.push(num)
	},
	setDrawMode(state, bool) {
		state.drawMode = bool
	},
	setPolygonIntersect(state, bool) {
		state.polygonIntersect = bool
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};