import {_senergy} from "@/OnlyLocalFiles/logins";
import dwdStore from "@/store/dwdStore";

let _accessToken = null;
let _accessPromise = null;
let _username = null;
let _password = null;

function _setLoginData(username, password){
    _username = username;
    _password = password;
}

async function _getAccessToken(errorFun){
    const url = _senergy.url;
    const details = {
        'grant_type': 'password',
        'username': _username,
        'password': _password,
        'client_id': 'sardine-platform'
    };

    let formBody = [];
    for (let key in details) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(details[key]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: formBody
    };

    return fetch(url, options)
        .then(response => {
            if(response.ok){
                return response.json();
            }
            return Promise.reject(response);
        })
        .then( data => {
            _accessToken = data['access_token'];
        })
        .catch((response) => {
            // get error messages, if any
            response.json().then(e => console.log(e));
            errorFun();
        });
}

async function createConnection(name, password, errorFun){
    if(name.length === 0 || password.length === 0) {
        name = _senergy.username;
        password = _senergy.password;
    }
    _setLoginData(name, password);
    _accessPromise = await _getAccessToken(errorFun);

    if(_accessToken) {
        setInterval(function () {
            _accessPromise = _getAccessToken(errorFun);
            // TODO: Für Demo, damit Zugriff immer möglich ist!
        }, 600000); // update aller 10min

        //_accessToken = _senergy.token;
        return true;
    }
    return false;
}

//format: https://developer.senergy.infai.org/api
//filterType?: '=' | '<>' | '!=' | '>' | '>=' | '<' | '<=';
async function getWarningsForDate(date){
    // Token noch nicht erhalten, warte so lange.
    if (_accessPromise){
        await _accessPromise;
    }

    // Token erhalten und es trat kein Fehler auf.
    if(_accessToken){
        return _getWarningsForDate(date);
    }
    // Falls Token nicht vorhanden ist.
    return null;
}

async function _getWarningsForDate(date){
    // TODO: checke Date vorher, ob es ein korrektes ist!
    // TODO: onError!
    const url = dwdStore.state.sensorQuery
    const id = dwdStore.state.unwetterwarnungenID
    const details = [{
        "time": {
            "start": date + "T00:00:00Z",
            "end": date + "T23:59:59Z"
        },
        "exportId": id,
        "columns": [
            {
                "name": "level"
            },
            {
                "name": "description"
            },
            {
                "name": "instruction"
            },
            {
                "name": "warnCellId"
            },
            {
                "name": "event"
            }
        ],
        "filters": []
    }];

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': "application/json",
            "authorization": "Bearer " + _accessToken
        },
        body: JSON.stringify(details)
    };

    let response = await fetch(url, options);
    if(!response.ok){
        // get error messages, if any
        response.json().then(e => console.log(e));
        return []
    }
    let json = await response.json();
    if (json.length === 0) {
        return []
    }
    json[0].forEach(value => {
        value[1] = value[1] < 5 ? parseInt(value[1]) - 1 : 4;

        let type = value[5];
        if(type.includes('BÖEN')) { type = 'sturm';}
        else if(type.includes('GEWITTER')) { type = 'gewitter';}
        else if(type.includes('REGEN')) { type = 'regen';}
        else if(type.includes('SCHNEE')) { type = 'schnee';}
        else if(type.includes('GLÄTTE') || type.includes('GLATTEIS')) { type = 'glatteis';}
        else if(type.includes('FROST')) { type = 'frost';}
        else if(type.includes('NEBEL')) { type = 'nebel';}
        else if(type.includes('TAUWETTER')) { type = 'tauwetter';}
        else if(type.includes('WÄRMEBELASTUNG') || type.includes('HITZE')) { type = 'hitze';}
        else if(type.includes('UV-INTENSITÄT') || type.includes('UV-INDEX')) { type = 'uv';}
        value.push(type);
    });

    return json[0]
}

async function getPegelForDate(date){
    // Token noch nicht erhalten, warte so lange.
    if (_accessPromise){
        await _accessPromise;
    }

    // Token erhalten und es trat kein Fehler auf.
    if(_accessToken){
        return _getPegelForDate(date);
    }
    // Falls Token nicht vorhanden ist.
    return null;
}

//alle 15 minuten neue daten...
//obere sächische wasserbehörde
async function _getPegelForDate(date){
    // TODO: checke Date vorher, ob es ein korrektes ist!
    // TODO: onError!
    const url = dwdStore.state.sensorQuery
    const id = dwdStore.state.pegelständeID
    const keys = dwdStore.state.pegelKeys
    let time = date.date
    let filterValue = date.name
    let startDate = (date.first_date ? date.first_date : time)
    let endDate = (date.last_date ? date.last_date : time)
    let columns = []
    for (let k of keys) {
        columns.push({"name": k})
    }

    const details = [{
        "time": {
            "start": startDate + "T00:00:00Z",
            "end":  endDate + "T23:59:59Z"
        },
        "exportId": id,
        "columns": columns,
        "filters": [{"column": "name", "type": "=", "value": filterValue}],
        "limit": 1000
    }];

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': "application/json",
            "authorization": "Bearer " + _accessToken
        },
        body: JSON.stringify(details)
    };

    let response = await fetch(url, options);
    if(!response.ok){
        // get error messages, if any
        response.json().then(e => console.log(e));
        return []
    }
    let json = await response.json();

    if (json.length === 0) {
        return []
    }
    let data = []
    json[0].forEach(value => {
        let val = {}
        val["time"] = value[0]
        for (let i = 1; i < value.length; i++) {
            val[keys[i-1]] = value[i]
        }
        data.push(val)
    });

    return data
}

async function getUBAForDate(date){
    // Token noch nicht erhalten, warte so lange.
    if (_accessPromise){
        await _accessPromise;
    }

    // Token erhalten und es trat kein Fehler auf.
    if(_accessToken){
        return _getUBAForDate(date);
    }
    // Falls Token nicht vorhanden ist.
    return null;
}

async function _getUBAForDate(date){
    // TODO: checke Date vorher, ob es ein korrektes ist!
    // TODO: onError!
    const url = dwdStore.state.sensorQuery
    const id = dwdStore.state.ubaID
    const keys = dwdStore.state.ubaKeys
    let time = date.date
    let filterValue = date.name
    let startDate = (date.first_date ? date.first_date : time)
    let endDate = (date.last_date ? date.last_date : time)
    let columns = []
    for (let k of keys) {
        columns.push({"name": k})
    }

    const details = [{
        "time": {
            "start": startDate + "T00:00:00Z",
            "end":  endDate + "T23:59:59Z"
        },
        "exportId": id,
        "columns": columns,
        "filters": [{"column": "station_name", "type": "=", "value": filterValue}],
        "limit": 1000        
    }];

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': "application/json",
            "authorization": "Bearer " + _accessToken
        },
        body: JSON.stringify(details)
    };

    let response = await fetch(url, options);
    if(!response.ok){
        // get error messages, if any
        response.json().then(e => console.log(e));
        return []
    }
    let json = await response.json();
    if (json.length === 0) {
        return []
    }
    let data = []
    json[0].forEach(value => {
        let val = {}
        val["time"] = value[0]
        for (let i = 1; i < value.length; i++) {
            val[keys[i-1]] = value[i]
        }
        data.push(val)
    });

    return data
}

async function getNiederschlagForDate(date){
    // Token noch nicht erhalten, warte so lange.
    if (_accessPromise){
        await _accessPromise;
    }

    // Token erhalten und es trat kein Fehler auf.
    if(_accessToken){
        return _getNiederschlagForDate(date);
    }
    // Falls Token nicht vorhanden ist.
    return null;
}

async function _getNiederschlagForDate(date){
    // TODO: checke Date vorher, ob es ein korrektes ist!
    // TODO: onError!
    const url = dwdStore.state.sensorQuery
    const id = dwdStore.state.niederschlagID
    const keys = dwdStore.state.niederschlagKeys
    let time = date.date
    let startDate = (date.first_date ? date.first_date : time)
    let endDate = (date.last_date ? date.last_date : time)
    let columns = []
    //sind über 38.000 polygone
    //console.log(startDate, endDate)
    for (let k of keys) {
        columns.push({"name": k})
    }
    const details = [{
        "time": {
            "start": "2023-03-20T00:00:00Z",
            "end": "2023-03-20T02:00:00Z", //"T23:59:59Z"
        },
        "exportId": id,
        "columns": columns,
        "filters": [
          {
               "column": "value",
               "type": ">=",
               "value": 0
           }
       ],
        "limit": 100000
    }];

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': "application/json",
            "authorization": "Bearer " + _accessToken
        },
        body: JSON.stringify(details)
    };

    let response = await fetch(url, options);
    if(!response.ok){
        // get error messages, if any
        response.json().then(e => console.log(e));
        return []
    }
    let json = await response.json();
    if (json.length === 0) {
        return []
    }
    let data = []
    json[0].forEach(value => {
        let val = {}
        val["time"] = value[0]
        for (let i = 1; i < value.length; i++) {
            val[keys[i-1]] = value[i]
        }
        data.push(val)
    });

    return data
}

async function getWetterForDate(date){
    // Token noch nicht erhalten, warte so lange.
    if (_accessPromise){
        await _accessPromise;
    }

    // Token erhalten und es trat kein Fehler auf.
    if(_accessToken){
        return _getWetterForDate(date);
    }
    // Falls Token nicht vorhanden ist.
    return null;
}
async function _getWetterForDate(date){
    // TODO: checke Date vorher, ob es ein korrektes ist!
    // TODO: onError!
    //TODO schauen warum es so viele sind. sind nur 40 stationen
    const url = dwdStore.state.sensorQuery
    const id = dwdStore.state.wetterID
    const keys = dwdStore.state.wetterKeys
    let time = date.date
    let filterValue = date.name
    let startDate = (date.first_date ? date.first_date : time)
    let endDate = (date.last_date ? date.last_date : time)
    let columns = []
    for (let k of keys) {
        columns.push({"name": k})
    }
    const details = [{
        "time": {
            "start": startDate + "T00:00:00Z",
            "end":  endDate + "T23:59:59Z"
        },
        "exportId": id,
        "columns": columns,
        "limit": 1000,  
        "filters": [{"column": "name", "type": "=", "value": filterValue}],
    }];

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': "application/json",
            "authorization": "Bearer " + _accessToken
        },
        body: JSON.stringify(details)
    };

    let response = await fetch(url, options);
    if(!response.ok){
        // get error messages, if any
        response.json().then(e => console.log(e));
        return []
    }
    let json = await response.json();

    if (json.length === 0) {
        return []
    }
    let data = []
    json[0].forEach(value => {
        let val = {}
        val["time"] = value[0]
        for (let i = 1; i < value.length; i++) {
            val[keys[i-1]] = value[i]
        }
        data.push(val)
    });

    return data
}

async function getWarningsForWeek(start, end){
    // Token noch nicht erhalten, warte so lange.
    if (_accessPromise){
        await _accessPromise;
    }

    // Token erhalten und es trat kein Fehler auf.
    if(_accessToken){
        const data = await _getWarningsForWeek(start, end);
        const obj = {};
        data.forEach(val => {
            const time = new Date(val[0]).getDay();
            if(obj[time]){
                //
            } else {
                obj[time] = new Set();
            }
            const event = val[1];
            // TODO: Gewitter umfasst verschiedene Events (Gewitter, Hagel, Regen, ...)
            // TODO: siehe _getWarningsForDate, da gleiches Vorgehen, vereinen!
            if(event.includes('BÖEN')) { obj[time].add('sturm');}
            else if(event.includes('GEWITTER')) { obj[time].add('gewitter');}
            else if(event.includes('REGEN')) { obj[time].add('regen');}
            else if(event.includes('SCHNEE')) { obj[time].add('schnee');}
            else if(event.includes('GERINGE GLÄTTE') || event.includes('GLÄTTE') || event.includes('GLATTEIS')) { obj[time].add('glatteis');}
            else if(event.includes('FROST')) { obj[time].add('frost');}
            else if(event.includes('NEBEL')) { obj[time].add('nebel');}
            else if(event.includes('TAUWETTER')) { obj[time].add('tauwetter');}
            else if(event.includes('WÄRMEBELASTUNG') || event.includes('HITZE')) { obj[time].add('hitze');}
            else if(event.includes('UV-INTENSITÄT') || event.includes('UV-INDEX')) { obj[time].add('uv');}
            else console.error(`Event nicht bekannt: ${event}`);
            //obj[time].add(event);
        });
        return obj;
    }
    // Falls Token nicht vorhanden ist.
    return null;
}

async function _getWarningsForWeek(start, end){
    // TODO: onError! + copy von getWarningsForDate
    const url = dwdStore.state.sensorQuery
    const id = dwdStore.state.unwetterwarnungenID
    const details = [{
        "time": {
            "start": start,
            "end": end
        },
        "exportId": id,
        "columns": [
            {
                "name": "event"
            },
            {
                "name": "level"
            }
        ],
        "filters": []
    }];

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': "application/json",
            "authorization": "Bearer " + _accessToken
        },
        body: JSON.stringify(details)
    };

    let response = await fetch(url, options);
    if(!response.ok){
        // get error messages, if any
        response.json().then(e => console.log(e));
        return []
    }
    let json = await response.json();
    return json[0]
}

export {createConnection, getWarningsForDate, getWarningsForWeek, getPegelForDate, getUBAForDate, getNiederschlagForDate, getWetterForDate}
