import * as CypherQueries from "@/modules/CypherQueries";
import {Vector as VectorLayer} from "ol/layer/";
import {Vector} from "ol/source";
import {Fill, Stroke, Style} from "ol/style";
import WKT from 'ol/format/WKT';
import {fromExtent} from 'ol/geom/Polygon';

const state = {
    isLoading: true,
    loadingMessage: [],
    documentsWithDateObj: {
        dIds: new Set(),
        featureMap: {},
        fCounter: 0,
        layer: new VectorLayer({
            source: new Vector({
                features: [],
                wrapX: false,
            }),
            name: 'documentWithDate',
            defaultStyle: new Style({
                fill: new Fill({
                    color: "#17621A7A"
                }),
                stroke: new Stroke({
                    color: '#000000',
                    width: 1
                })
            }),
        })
    },
    personsAndCompanies: { // TODO: Warum im Store gespeichert?
        persons: [],
        companies: [],
    },
    documentsWithPersonsAndCompaniesObj: {
        dIds: new Set(),
        featureMap: {},
        fCounter: 0,
        layer: new VectorLayer({
            source: new Vector({
                features: [],
                wrapX: false,
            }),
            name: 'documentWithPersonsAndCompanies',
            defaultStyle: new Style({
                fill: new Fill({
                    color: "#17621A7A"
                }),
                stroke: new Stroke({
                    color: '#000000',
                    width: 1
                })
            }),
        }),
    },
    datesWithCats: [],
    documentsWithSearchStringObj: {
        dIds: new Set(),
        featureMap: {},
        fCounter: 0,
        layer: new VectorLayer({
            source: new Vector({
                features: [],
                wrapX: false,
            }),
            name: 'documentSearchString',
            defaultStyle: new Style({
                fill: new Fill({
                    color: "#17621A7A"
                }),
                stroke: new Stroke({
                    color: '#000000',
                    width: 1
                })
            }),
        }),
    },
};

const getters = {
    isLoading: state => state.isLoading,
    getLoadingMessage: state => state.loadingMessage,
    getDocumentsWithDate: state => state.documentsWithDateObj,
    getPersonsAndCompanies: state => state.personsAndCompanies,
    getDocumentsWithPersonsAndCompanies: state => state.documentsWithPersonsAndCompaniesObj,
    getDates: state => state.datesWithCats,
    getDocumentsWithSearchString: state => state.documentsWithSearchStringObj,
};

const mutations = {
    /* synchronously */

    /*setExample(state, data){
        state.example = data;
    },*/
    setLoading(state, loading){
        state.isLoading = loading;
    },
    setLoadingMessage(state, message){
        state.loadingMessage = message;
    },
    setDocumentsWithDate(state, data){
        console.log(data)
        state.documentsWithDateObj.dIds = data.dIds;
        state.documentsWithDateObj.featureMap = data.featureMap; // TODO: Speichern oder nicht?
        state.documentsWithDateObj.fCounter = data.fCounter;

        state.documentsWithDateObj.layer.getSource().clear();
        Object.keys(data.featureMap).forEach(category => {
            data.featureMap[category].forEach(fId => {
                if(data.layerData[category] && data.layerData[category].layer) {
                    const f = data.layerData[category].layer.getSource().getFeatureById(fId);
                    if(f) {
                        state.documentsWithDateObj.layer.getSource().addFeature(f);
                    } else {
                        // invalid shape = true?
                        console.error("Feature mit ID:", fId, "in der Kategorie", category, "nicht gefunden!");
                    }
                }
            });
        });
    },
    setPersonsAndCompanies(state, data){
        data.persons.forEach(name => {
            state.personsAndCompanies.persons.push(name);
        });
        //state.personsAndCompanies.persons = data.persons;
        state.personsAndCompanies.companies = data.companies;
    },
    setDocumentsWithPersonsAndCompanies(state, data){
        state.documentsWithPersonsAndCompaniesObj.dIds = data.dIds;
        state.documentsWithPersonsAndCompaniesObj.featureMap = data.featureMap; // TODO: Speichern oder nicht?
        state.documentsWithPersonsAndCompaniesObj.fCounter = data.fCounter;

        state.documentsWithPersonsAndCompaniesObj.layer.getSource().clear();
        Object.keys(data.featureMap).forEach(category => {
            data.featureMap[category].forEach(fId => {
                if(data.layerData[category] && data.layerData[category].layer) {
                    const f = data.layerData[category].layer.getSource().getFeatureById(fId);
                    if(f) {
                        state.documentsWithPersonsAndCompaniesObj.layer.getSource().addFeature(f);
                    } else {
                        // invalid shape = true?
                        console.error("Feature mit ID:", fId, "in der Kategorie", category, "nicht gefunden!");
                    }
                }
            });
        });
    },
    setDates(state, data) {
        state.datesWithCats = data
    },
    setFeaturesByDocumentIDs(state, data){
        state.documentsWithSearchStringObj.dIds = data.dIds;
        state.documentsWithSearchStringObj.featureMap = data.featureMap; // TODO: Speichern oder nicht?
        state.documentsWithSearchStringObj.fCounter = data.fCounter;

        state.documentsWithSearchStringObj.layer.getSource().clear();
        Object.keys(data.featureMap).forEach(category => {
            data.featureMap[category].forEach(fId => {
                if(data.layerData[category] && data.layerData[category].layer) {
                    const f = data.layerData[category].layer.getSource().getFeatureById(fId);
                    if(f) {
                        state.documentsWithSearchStringObj.layer.getSource().addFeature(f);
                    } else {
                        // invalid shape = true?
                        console.error("Feature mit ID:", fId, "in der Kategorie", category, "nicht gefunden!");
                    }
                }
            });
        });
    },
};

const actions = {
    /* asynchronously */
    /* Example:
    incrementAsync({commit}){
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                commit('increment');
                resolve();
            }, 1000)
        });
    },*/

    /*setExample({commit}){
        commit('setExample');
    },*/
    loadDates(context) {
        return CypherQueries.loadDates().then(data => {
            context.commit('setDates', data);
        });
    },
    loadDatesEnvirVis(context) {
        return CypherQueries.loadDatesEnvirVis().then(data => {
            context.commit('setDates', data);
        });
    },    
    setLoading({commit}, loading){
        commit('setLoading', loading);
    },
    setLoadingMessage({commit}, message){
        commit('setLoadingMessage', message);
    },
    loadDocumentsWithDate(context, date){
        return CypherQueries.loadDocumentIdsWithDate(date.from, date.to, date.geboteLabels, date.verboteLabels).then(data => {
            context.commit('setDocumentsWithDate', data);
        });
    },
    loadDocumentsWithDateById(context, date){
        return CypherQueries.getFeaturesByDocumentIdsWithDate(date.from, date.to, date.ids, date.brutzeiten).then(data => {
            context.commit('setDocumentsWithDate', data);
        });
    },    
    loadPersonsAndCompanies(context){
        return CypherQueries.loadPersonsAndCompanies().then(data => {
            context.commit('setPersonsAndCompanies', data);
        });
    },
    loadDocumentIdsWithPersonsAndCompanies({commit}, names){
        return CypherQueries.loadDocumentIdsWithPersonAndCompany(names.persons, names.companies).then(data => {
            commit('setDocumentsWithPersonsAndCompanies', data);
        });
    },
    loadFeaturesByDocumentIDs({commit}, docIDs){
        return CypherQueries.loadFeaturesByDocumentIDs(docIDs).then(data => {
            commit('setFeaturesByDocumentIDs', data);
        });
    },
    savePolygonInDB({commit}, data){
        return CypherQueries.savePolygonInDB(data.layerName, data.wkt);
    },
    loadFeaturesInRegion(context, data) {
        const format = new WKT();
        let wkt = format.writeGeometry(fromExtent(data.extent), {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });
        return CypherQueries.loadFeaturesInRegion(wkt, data.layerNames).then(res => {
            context.commit('setDates', res);
        });
    },
    loadFeaturesInRegionEnvirVis(context, data) {
        const format = new WKT();
        let wkt = format.writeGeometry(fromExtent(data.extent), {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });
        return CypherQueries.loadFeaturesInRegionEnvirVis(wkt, data.layerNames).then(res => {
            context.commit('setDates', res);
        });
    }    
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};