const state = {
    draggedElement: null,
    updater: 0,
    current: null, // TODO: rename!
    isEmptyTree: true,
}

const getters = {
    getDraggedElement: state => state.draggedElement,
    getUpdater: state => state.updater,
    getCurrent: state => state.current,
    isEmptyTree: state => state.isEmptyTree,
}

const mutations = {
    setDraggedElement(state, element){
        state.draggedElement = element;
    },
    setUpdater(state){
        state.updater += 1;
    },
    setCurrent(state, data){
        state.current = data;
    },
    setTreeIsEmpty(state, value){
        state.isEmptyTree = value;
    }
}

const actions = {

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};