import Vue from "vue";

const state = {
    view: null,
    viewCenter: [],
    //selectedFeatures: new Set(), // In Vue2 not reactive!
    selectedFeatures: {},
    map: null,
    lidarBorder: "",
};

const getters = {
    getView: state => state.view,
    getViewCenter: state => state.viewCenter,
    getSelectedFeatures: state => state.selectedFeatures,
    getMap: state => state.map,
    getLidarBorder: state => state.lidarBorder,
};

const mutations = {
    /* synchronously */
    setView(state, view){
        state.view = view;
    },
    setViewCenter(state, viewCenter){
        state.viewCenter = viewCenter;
        // TODO: change center in state.view?
    },
    addFeatureToSelection(state, feature){
        Vue.set(state.selectedFeatures, feature.getId(), feature);
    },
    removeFeatureFromSelection(state, feature){
        Vue.delete(state.selectedFeatures, feature.getId());
    },
    clearSelectedFeatures(state){
        state.selectedFeatures = {};
    },
    setMap(state, map) {
        state.map = map
    },
    setLidarBorder(state, border){
        state.lidarBorder = border;
    },
};

const actions = {
    setView({commit}, view){
        commit('setView', view);
    },
    setViewCenter({commit}, viewCenter){
        commit('setViewCenter', viewCenter);
    },
    addFeatureToSelection({commit}, feature){
        commit('addFeatureToSelection', feature);
    },
    removeFeatureFromSelection({commit}, feature){
        commit('removeFeatureFromSelection', feature);
    },
    clearSelectedFeatures({commit}){
        commit('clearSelectedFeatures');
    },
    setMap({commit}, map) {
        commit('setMap', map);
    },
    setLidarBorder({commit}, border) {
        commit('setLidarBorder', border);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};