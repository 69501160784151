import Vue from 'vue'
import Vuex from 'vuex';
import App from './App.vue'
import UniqueId from "vue-unique-id"
import dwdStore from "@/store/dwdStore";
import neo4jStore from "@/store/neo4jStore";
import autoComplete from "@tarekraafat/autocomplete.js";
import router from './router'
import componentsStore from "@/store/componentsStore";
import vuetify from './plugins/vuetify'

import createPersistedState from "vuex-persistedstate";
import mapStore from "@/store/mapStore";
import mainFilterStore from "@/store/mainFilterStore";

Vue.config.productionTip = true;
Vue.use(UniqueId);
Vue.use(Vuex);
Object.defineProperty(Vue.prototype, '$autoComplete', { value: autoComplete });

const store = new Vuex.Store({
    modules: {
        dwdStore: dwdStore,
        neo4jStore: neo4jStore,
        componentsStore: componentsStore,
        mapStore: mapStore,
        mainFilterStore: mainFilterStore,
    },
    plugins: [
        createPersistedState({
            paths: ['componentsStore'],
        }),
    ],
});

new Vue({
    render: h => h(App),
    store,
    router,
    vuetify,
}).$mount('#mainApp')
